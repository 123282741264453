<template>
    <div class='lk__cart-item justify-content-start'>
        <div class='d-flex flex-column justify-content-center me-2 text-center u-width-50'>
            <img class='d-block m-auto' :src="getCryptoImageSrc()" width='25px' />
            <span class='o-text-small'>{{getNetworkTitle()}}</span>
        </div>
        <div class="d-flex flex-column me-2">
            <span :style="data.active == 0 ? 'color: gray;' : ''" class='me-3 cursor-pointer u-font-size-16' @click="copyToClipboard">
                {{ isCopied ? 'Скопировано!' : getShortenedValue() }}
            </span>
            <span v-if="data.description != null" class='walletDesc text-cut'>{{getShortenedDescription()}}</span>
        </div>
        <button class="ms-auto" @click='wallet_delete'><img src='/img/ico/ico-danger.svg' /></button>
    </div>
</template>

<script>
export default {
    props: ['data', 'networks', 'crypto'],
    data() {
        return {
            isCopied: false,
        }
    },
    methods: {
        wallet_delete() {
            this.$emit('wallet_delete');
        },
        getCryptoImageSrc() {
            if (this.crypto && this.networks) {
                return '/img/ico/ico-' + this.crypto.find(c => c.id === this.getNetworkCryptoId()).title.toLowerCase() + '--color.png';
            }
            return '/img/ico/ico-bitcoin--color.png'; // Возвращаем значение по умолчанию
        },
        getNetworkTitle() {
            if (this.networks) {
                return this.networks.find(n=> n.id === this.data.network_id).title;
            }
            return 'unknown'; // Возвращаем null, если данные отсутствуют
        },
        getNetworkCryptoId() {
            if (this.networks) {
                return this.networks.find(n=> n.id === this.data.network_id).crypto_id;
            }
            return 'unknown'; // Возвращаем null, если данные отсутствуют
        },
        getShortenedValue() {
            if (this.data.value) {
                return this.data.value.length > 20 ? this.data.value.substr(0, 16) + '...' + this.data.value.substring(this.data.value.length - 4) : this.data.value;
            }
            if (this.data.wallet_hash) {
                return this.data.wallet_hash.length > 20 ? this.data.wallet_hash.substr(0, 16) + '...' + this.data.wallet_hash.substring(this.data.wallet_hash.length - 4) : this.data.wallet_hash;
            }
            return null; // Возвращаем null, если значение отсутствует
        },
        getShortenedDescription() {
            if (this.data.description) {
                return this.data.description.substr(0, 20);
            }
            return null; // Возвращаем null, если описание отсутствует
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.data.wallet_hash ? this.data.wallet_hash : this.data.value).then(() => {
                this.isCopied = true;
                setTimeout(() => {
                    this.isCopied = false;
                }, 1000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    }
}
</script>

<style scoped>
    .text-cut {
        max-width: 300px;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }
.fs-7 {
    font-size: 13px;
}
@media (max-width:500px) {
    .fs-7 {
        font-size: 10px;
    }
}
</style>
