<template>
    <section class="recovery_section vh-100">
        <div class="container">
            <div class="row">
                <div class="col-5 d-none d-md-block">

                    <div class="d-flex flex-column align-items-center flex-lg-row">
                        <div class="left w-100">
                            <h2>Подписывайся <br> на наш телеграм</h2>
                            <a target="_blank" href="https://t.me/valutix" class="button w-100 fs-5">Подписаться</a>
                        </div>
                        <div class="right">
                            <img src="/static/img/reg_tg2.png" alt="telegram">
                        </div>
                    </div>

                </div>
                <!-- Шаг 1 -->
                <div class="col-12 col-md-5 mx-auto z-index-2 step_1" v-if="step_1">
                    <h1>Востановление пароля</h1>
                    <div class="mb-4">
                        <span class="fs-6">На указанный email придет ссылка для востановления пароля</span>
                    </div>
                    <div class="mb-4 position-relative">
                        <input type="email" class="form-control shadow-none" id="enter_mail" v-model="email">
                        <div class="form-text text_warning" v-if="error !== ''">{{ error }}</div>
                    </div>
                    <div class="enter-btn">
                        <button class="button w-100 mw-100" :disabled="email == ''" @click="forgot_pass">Отправить</button>
                    </div>
                
                    <span class="d-block text-center">Нет аккаунта? <router-link to="/registration">Создать аккаунт</router-link></span>
                </div>

                <!-- Шаг 2 -->
                <div class="col-12 col-md-5 mx-auto z-index-2 step_2" v-if="step_2">
                    <h1>Востановление пароля</h1>
                    <span class="d-block mb-4">Мы отправили код на {{ email }}</span>
                    <div class="pincode mb-4 position-relative">
                        <input
                            v-for="(item, index) in pinInputs"
                            :key="index"
                            type="number"
                            v-model="pinInputs[index]"
                            @input="handleInput(index)"
                            ref="inputs"
                            maxlength="1"
                        >
                        <div class="form-text text_warning" v-if="error !== ''">{{ error }}</div>
                      </div>
                      <div class="enter-btn">
                        <button class="button w-100 mw-100 next_2" :disabled="!pinInputs.every(input => input.length > 0)" @click="thirdStep">Далее</button>
                     </div>
                    <span class="d-block text-center opacity-0">Нет аккаунта? <router-link to="/registration">Создать аккаунт</router-link></span>
                </div>

                <!-- Шаг 3 -->
                <div class="col-12 col-md-5 mx-auto z-index-2 step_3" v-if="step_3">
                    <h1 class="mb-4">Создайте новый пароль</h1>
                    <div class="input-group mb-4">
                        <input type="password" v-model="password" class="form-control border-0 shadow-none">
                        <span class="input-group-text bg-white">
                            <img src="/static/img/remove_red_eye.svg" class="cursor-pointer" @click="showPassword($event.target)">
                        </span>
                        </div>
                    <div class="mb-4">
                        <span class="fs_16 ul_title">Ваш пароль должен содержать:</span>
                        <ul>
                            <li :class="{ 'text_warning': !isLengthValid }">Длина от 8 до 32 символов</li>
                            <li :class="{ 'text_warning': !hasLowercaseLetter }">Хотя бы одну строчную букву</li>
                            <li :class="{ 'text_warning': !hasUppercaseLetter }">Хотя бы одну прописную букву</li>
                            <li :class="{ 'text_warning': !hasDigit }">Хотя бы одну цифру</li>
                        </ul>
                    </div>
                    <div class="mb-4 position-relative" v-if="isPasswordValid">
                        <label for="basic-url" class="form-label">Повторите пароль</label>
                        <div class="input-group">
                            <input type="password" class="form-control border-0 shadow-none" v-model="password_repeat">
                            <span class="input-group-text bg-white">
                                <img src="/static/img/remove_red_eye.svg" class="cursor-pointer"  @click="showPassword($event.target)">
                            </span>
                        </div>
                        <span class="form-text text_warning fs_13" v-if="isPasswordValid && (password !== password_repeat)">Пароли не совпадают</span>
                        </div>
                    <button class="next_btn button w-100 mw-100" :disabled="!isShowNext" @click="toEnter">Сохранить</button>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Recovery",
        data() {
            return {
                pinInputs: ['', '', '', '', '', ''],
                email: '',
                password: '',
                password_repeat: '',
                step_1: true,
                step_2: false,
                step_3: false,
                step_4: false,
                error: '',
                sessionId: 0,
            };
        },
        computed: {
        // Вычисляемое свойство для объединения значений из всех инпутов
            pincode() {
                return this.pinInputs.join('');
            },
            isLengthValid() {
                return this.password.length >= 8 && this.password.length <= 32;
            },
            hasLowercaseLetter() {
                return /[a-z]/.test(this.password);
            },
            hasUppercaseLetter() {
                return /[A-Z]/.test(this.password);
            },
            hasDigit() {
                return /\d/.test(this.password);
            },
            isPasswordValid() {
                return this.isLengthValid && this.hasLowercaseLetter && this.hasUppercaseLetter && this.hasDigit ? true : false;
            },
            isShowNext() {
                return this.isPasswordValid && (this.password === this.password_repeat) ? true : false;
            }
        },
        methods: {
            handleInput(index) {
                if (this.pinInputs[index].length > 1) {
                    // Если введено более одного символа, переносим остаток в следующие инпуты
                    const overflow = this.pinInputs[index].slice(1); // Получаем остаток строки
                    this.pinInputs[index] = this.pinInputs[index][0]; // Оставляем в текущем инпуте только первую цифру
                    for (let i = 0; i < overflow.length && (index + i + 1) < this.pinInputs.length; i++) {
                    this.$set(this.pinInputs, index + i + 1, overflow[i]);
                    }
                }

                // Перемещаем фокус на следующий инпут, если текущий заполнен
                if (this.pinInputs[index].length === 1 && index < this.pinInputs.length - 1) {
                    this.$refs.inputs[index + 1].focus();
                }
            },
            showPassword(eventTarget) {
                let myInput = $(eventTarget).closest('.input-group').find('.form-control');
                myInput.attr('type', function(index, currentType) {
                    return currentType === 'password' ? 'text' : 'password';
                });
            },

            forgot_pass() {
                if(this.$metrika !== undefined) {
                    this.$metrika.reachGoal('password_reset');
                }
                let data = {
                    email: this.email,
                }
                axios.post(this.$api_address + '/users/check_email', data, {
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                }).then(response => {
                    if(typeof(response.data.data) != "undefined" && response.data.data !== null) {
                        console.log(response);
                        this.step_1 = false;
                        this.step_2 = true;
                        this.sessionId = response.data.data.session_id;
                    } else {
                        if(typeof(response.data.message) != "undefined" && response.data.message !== null){
                            this.error = response.data.errors.email.pop();
                        }
                    }
                })
            },
            
            thirdStep() {
                let data = {
                    code: this.pincode,
                    session_id: this.sessionId,
                }

                axios.post(this.$api_address + '/users/check_code', data, {
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                }).then(response => {
                    if(response.data.message) {
                        this.error = 'Неверный код';
                    } else {
                        this.step_2 = false;
                        this.step_3 = true;
                        sessionStorage.setItem('session_id', this.sessionId);
                    }
                }).catch(function (error){
                    this.error = 'Неверный код';
                });
            },

            lastStep(){
                let data = {
                    email: this.email,
                    password: this.password,
                    password_repeat: this.password_repeat,
                }

                axios.post(this.$api_address + '/users/change_password', data, {
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                }).then(response => {
                if(typeof(response.data.data.response) != "undefined" && response.data.data.response !== null) {
                        this.toEnter;
                    } else {
                        if(typeof(response.data.errors.password) != "undefined" && response.data.errors.password !== null){
                            this.errorText.password = response.data.errors.password.pop();
                        }
                        if(typeof(response.data.errors.password_repeat) != "undefined" && response.data.errors.password_repeat !== null){
                            this.errorText.password_repeat = response.data.errors.password_repeat.pop();
                        }
                    }
                })
            },

            toEnter(){
                this.$router.push({ name: 'login', params: { fromRecovery: 'true' } });
            }
        },
    }

</script>

<style scoped>

    h1, .h1 {
        font-size: 30.5px;
        font-weight: 600;
        margin-bottom: 8px;
        text-transform:unset;
    }

    a {
        font-size: inherit;
        color: #69E278;
        text-underline-offset: 5px;
    }

    h2, .h2 {
        font-size: 25px;
        font-weight: 600;
    }

    .fs_16 {
        font-size: 16px;
    }

    .list_title {
        margin-bottom: 16px;
    }

    .recovery_section {
        padding: 74px 0 84px;
        background: left 200px bottom 0 no-repeat url(/public/static/img/gradient.png);
        position: relative;
    }

    .recovery_section::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 54%;
        background-color: #35634F;
        z-index: 0;
    }

    .recovery_section .top {
        margin-bottom: 125px;
    }

    button:disabled {
        opacity: 0.3;
    }

    .enter-btn {
        margin-bottom: 191px;
    }

    .text_warning {
        color: #FF6D6D !important;
    }

    .form-text {
        font-size: 13px;
        position: absolute;
        bottom: -20px;
        left: 0;
    }

    .pincode { 
	    text-align: center;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
    .pincode input[type="number"] {
        display: inline-block;
        border: none;
        border-radius: 5px;
        width: 68px;
        height: 68px;
        line-height: 50px;
        text-align: center;
        color: #212121;
        font-size: 30.5px;
        font-style: normal;
        font-weight: 400;
        padding: 16px 0px;
        outline: none;
        background-color: #fff;
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
    }
    .pincode input[type="number"]::-webkit-outer-spin-button,
    .pincode input[type="number"]::-webkit-inner-spin-button {
        display: none;
    }
    
    .pincode input[type="number"]:focus {
        border: 2px solid #1dd216;
    }

    .recovery_section .step_3 ul li {
        color: #74ED79;
    }

    @media(max-width: 1024px) {
        .pincode input[type="number"] {
            width: 50px;
            height: 50px;
        }
    }


    @media(max-width: 768px) {
        .recovery_section {
            padding: 50px 0;
        }
        .recovery_section::after {
            display: none;
        }
    }

    @media(max-width: 425px) {
        h1 {
            margin-bottom: 57px;
            font-size: 25px;
        }
        .recovery_section {
            padding: 30px 26px 50px;
            background: left -200px bottom -150px no-repeat url(/public/static/img/gradient.png);
        }

        .enter-btn {
            margin-bottom: 200px;
        }

        .pincode input[type="number"] {
            width: 35px;
            height: 50px;
        }

        .step_3 li {
            margin-bottom: 4px;
        }
    }

</style>