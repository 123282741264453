<template>
    <fragment>
        <div class="row mt-4 mb-3">
            <div class="col d-flex align-items-end">
                <h3 class="mb-0">Счет №{{ invoice.user_id }}-{{ invoice.id }}</h3>
                <span class="ms-5">{{ invoice.status }}</span>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12 col-sm-3">
                <span>К оплате</span>
            </div>
            <div class="col-12 col-sm-6">
                <div class="col">
                    <span class="fw-bold">{{ invoice.commission_type == 'Плательщик' ? parseFloat(invoice.amount) + parseFloat(invoice.commission) :
                        invoice.amount }} {{ invoice.currency.short_title }}</span>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12 col-sm-3">
                <span>Описание</span>
            </div>
            <div class="col-12 col-sm-6">
                <div class="col">
                    <span>{{ invoice.description }}</span>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-2">
            <div class="col">
                <h3 class="mb-0">Оплата счёта картой</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="modal-body">
                    <div class="custom-modal__select-card grid-cards invoice-border mb-2 pb-3">
                        <div @click="selectPaymentMethod(bank)" v-for="bank in bank_list['ru']" :key="bank.id" class="custom-modal__select-card-wrapper"
                         :class="bank == selectedPaymentMethod ? 'custom-modal__select-card-wrapper--selected' : ''">
                            <div class="custom-modal__select-card-container align-items-center justify-content-center">
                                <img :src="bank.logo" width="80px" height="50px" />
                                <div style="position: relative">
                                    <img class="flag" src="/static/img/invoice/first/rus_flag.svg" width="20" height="13">
                                </div>
                            </div>
                        </div>

                        <div @click="selectPaymentMethod(bank)" v-for="bank in bank_list['kz']" :key="bank.id" class="custom-modal__select-card-wrapper"
                            :class="bank == selectedPaymentMethod ? 'custom-modal__select-card-wrapper--selected' : ''">
                            <div class="custom-modal__select-card-container align-items-center justify-content-center">
                                <img :src="bank.logo" width="80px" height="50px" />
                                <div style="position: relative">
                                    <img class="flag" src="/static/img/invoice/first/kz_flag.svg" width="20" height="13">
                                </div>
                            </div>
                        </div>

                        <div @click="selectPaymentMethod(bank)" v-for="bank in bank_list['uz']" :key="bank.id" class="custom-modal__select-card-wrapper"
                            :class="bank == selectedPaymentMethod ? 'custom-modal__select-card-wrapper--selected' : ''">
                            <div class="custom-modal__select-card-container align-items-center justify-content-center">
                                <img :src="bank.logo" width="80px" height="50px" />
                                <div style="position: relative">
                                    <img class="flag" src="/static/img/invoice/first/uz_flag.svg" width="20" height="13">
                                </div>
                            </div>
                        </div>
                        
                        <div @click="selectPaymentMethod(bank)" v-for="bank in bank_list['eu']" :key="bank.id" class="custom-modal__select-card-wrapper"
                        :class="bank == selectedPaymentMethod ? 'custom-modal__select-card-wrapper--selected' : ''">
                        <div class="custom-modal__select-card-container align-items-center justify-content-center">
                            <img :src="bank.logo" width="80px" height="50px" />
                        </div>
                    </div>
                    </div>
                    <div class="col">
                        <h3 class="mb-0">Оплата счёта криптовалютой</h3>
                    </div>
                    <div class="custom-modal__select-card grid-cards mt-3 mb-4">
                        <div @click="selectPaymentMethod(wallet)" v-for="wallet in depositWallets" class="custom-modal__select-card-wrapper" :class="wallet == selectedPaymentMethod ? 'custom-modal__select-card-wrapper--selected' : ''">
                            <div class="custom-modal__select-card-container align-items-center justify-content-center">
                                <img :src="'/img/ico/ico-' + crypto.find(c => c.id == wallet.crypto_id).title.toLowerCase() + '--color.png'"
                                    width="20px" height="20px" />
                                <div class="custom-modal__select-card-right"><span class="fw-bold">{{ crypto.find(c => c.id
                                    == wallet.crypto_id).short_title }}</span><span>{{ wallet.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="button mb-4 fixed-button" disabled @click="confirmPaymentMethod">Подтвердить</button>
    </fragment>
</template>

<script>
export default {
    props: ['invoice', 'crypto', 'bank_list'],
    data() {
        return {
            selectedPaymentMethod: null,
            selectedCountry: 'ru',
            crypto_id: 0,
            networks: [],
            wallets: [],
        }
    },
    methods: {
        selectPaymentMethod(method) {
            this.selectedPaymentMethod = method;
            document.querySelector('.fixed-button').removeAttribute("disabled")
        },

        confirmPaymentMethod() {
            // Отправляем событие с сохраненными данными при нажатии на кнопку
            this.$emit('select_method', { 
                selectedPaymentMethod: this.selectedPaymentMethod,
            });
        }
    },
    created() {
        axios.get(this.$api_address + '/networks').then(response => {
            this.networks = response.data.data;
        });

        axios.get(this.$api_address + '/deposit_wallets').then(response => {
            this.wallets = response.data.data;
        });

    },
    computed: {
        /*Собираем все уникальные сети для кошельков, 
        по одному кошельку для каждой валюты*/
        depositWallets() {
            let data = [];
            this.wallets.forEach((value, index) => {
                let temp = this.networks.find(network => network.id == value.network_id);
                if (temp) {
                    let netWallet = {
                        ...temp,
                        ...value
                    }
                    if (data.find(network => network.network_id == netWallet.network_id) == null) {
                        data.push(netWallet);
                    }
                }
            });
            return data;
        }
    }
}
</script>

<style scoped>
    .flag {
        position: absolute;
        width: 20px;
        top: -30px;
        right: -10px;
        height: 20px;
    }
    @media(max-width: 500px) {
        .fixed-button {
            width: 100%;
            position: sticky;
            max-width: unset;
            bottom: 0;
        }
    }
</style>
