<template>
    <fragment>
    <section class="first_cash_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9 z-1 order-2 order-md-1">
                    <h1>Купить или продать
                        валюту за <span class="text-green">наличные</span></h1>
                    <span class="h2 text-green subtitle d-block">В удобном для Вас городе</span>
                    <div class="d-flex flex-column flex-md-row gap-3">
                        <router-link exact class="myButton text-nowrap" 
                        :to="{
                            path: user && user.length !== 0 ? '/cash-exchange' : '/registration',
                            query: { action: 'buy' }
                        }">Купить за наличные</router-link>
                        <router-link exact class="myButton text-nowrap" 
                        :to="{
                            path: user && user.length !== 0 ? '/cash-exchange' : '/registration',
                            query: { action: 'sale' }
                        }">Продать за наличные</router-link>
                    </div>
                </div>
                <div class="col-12 col-md-3 d-flex justify-content-end justify-content-md-start order-1 order-md-2">
                    <img src="/static/img/cash/cash_first_section.png" class="first_img z-0">
                </div>
            </div>
        </div>
    </section>
    
    <section class="second_cash_section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                        <img src="/static/img/cash/map.png" alt="map" class="second_cash_section_map position-relative z-0">
                    <div class="second_section_info position-relative z-1">
                        <span class="border border-white rounded px-3 py-1 mb-2 mb-md-1 d-inline-block">Безопасно и конфиденциально</span>
                        <h2>Операции с наличными</h2>
                        <ul class="second_section_list custom_ul p-0 gap-3 flex-wrap">
                            <li>Доставка средств или получение в офисе. Гарантируем полную конфиденциальность.</li>
                            <li>Выгодный курс, минимальные комиссии и любые объемы.</li>
                            <li>Работаем 24/7, поддержка всегда на связи.</li>
                            <li>Безупречный информационный сервис, удобный интерфейс.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Numbers/>
    </fragment>
</template>

<script>
    import Numbers from '@/components/sections/Numbers.vue'

    export default {
        name: 'Cash',
        props: ['user'],
        components: {
            Numbers,
        },
        
    }
</script>

<style scoped>
    h1 {
        max-width: 788px;
        font-size: 70.5px;
        font-weight: 700;
        line-height: 90.49%;
        margin-bottom: 20px;
        text-transform: none;
    }
    
    h2, .h2 {
        font-size: 30.5px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    
    h6 {
        font-size: 20px;
        font-weight: 600;
    }

    .subtitle {
        margin-bottom: 110px;
    }

    .first_cash_section {
        padding: 116px 0 22px;
    }

    .second_section_info {
        margin-top: -240px;
    }

    .second_section_list {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
 
    .second_section_list li {
        align-items: flex-start;
        max-width: 240px;
    }

    .second_section_list li:before {
        margin-top: 5px;
    }

    .first_img {
        margin-left: -250px;
        margin-top: -50px;
    }

    .second_cash_section_map {
        max-width: 100%;
    }

    @media(max-width: 768px) {
        .second_section_info {
            margin-top: -100px;
        }

        .first_img {
            margin-left: unset;
            margin-top: unset;
            margin-right: -150px;
            margin-bottom: -82px;
            /* max-width: 374px; */
        }

        .first_cash_section {
            padding: 50px 0 100px;
        }
    }

    @media(max-width: 500px) {
        h1 {
            font-size: 30.5px;
            max-width: 232px;
        }

        section {
            padding: 50px 0 50px;
        }

        .second_cash_section {
            padding-bottom: 0;
        }

        .first_img {
            max-width: 374px;
        }

        .container {
            padding-right: 38px;
            padding-left: 38px;
        }

        .subtitle {
            margin-bottom: 47px;
            font-size: 16px;
            font-weight: 500;
        }

        .second_section_list li {
            max-width: unset;
        }

        .second_cash_section_map {
            transform: scale(1.8);
            margin-left: 67px;
        }


    }

    
</style>