<template>
    <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-sm">
            <div class="modal-content bg_green">
                <div class="modal-header border-0">
                    <h1 class="modal-title h2 m-0" id="supportModalLabel">{{ successText ? successText : title }}</h1>
                    <button type="button" class="btn-close shadow-none btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="successTextreset"></button>
                </div>
                <div class="modal-body">
                    <div :class="successText ? 'opacity-0' : ''">
                        <div class="mb-3 position-relative">
                            <input type="text" class="form-control form-control-lg fs-5 border-0 shadow-none" id="name" placeholder="Ваше имя" v-model="name">
                        </div>
                            
                        <div class="mb-3 position-relative">
                            <input type="email" class="form-control form-control-lg fs-5 border-0 shadow-none" id="email_input" aria-describedby="emailHelp" placeholder="Email" v-model="email">
                        </div>
                        <div class="mb-3">
                            <textarea class="form-control form-control-lg border-0 shadow-none" id="textarea_input" rows="4" placeholder="Сообщение" v-model="message"></textarea>
                        </div>
                        <div class="mb-3" v-if="errorText">
                            <span class="fs-6 text-center mx-auto text-danger d-block">{{errorText}}</span>
                        </div>
                        <button class="myButton mw-100" @click="send">Отправить</button>
                        <div class="form-text mt-3 text-white text-center">
                            Нажимая на кнопку “Отправить”, вы соглашаетесь с правилами сервиса и политикой конфиденциальности.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            name: '',
            email: '',
            message: '',
            successText: '',
            errorText: '',
            title: 'Отправить письмо'
        }
    },

    methods: {
        send() {
            let type;
            if(this.$route.name === "api") {
                type = 'api';
            }
            if(this.$route.name === "contacts") {
                type = 'support';
            }
            if(this.$route.name === "invoices") {
                type = 'invoice';
            }
            let data = {
                    name: this.name,
                    email: this.email,
                    message: this.message,
            }
            if(this.name && this.email) {
                axios
                .post(this.$api_address + "/send_form/" + type, data, {
                    validateStatus: function (status) {
                        return status < 500;
                    }
                }).then((response) => {
                    this.successText = 'Письмо отправлено';
                    this.errorText = ''
                });
            }
            else {
                this.errorText = 'Заполните все поля';
                this.successText = '';

                }
        },
        successTextreset() {
                this.successText = '';
                this.message = '';
                this.email = '';
                this.name = '';
        }
    }
}
</script>

<style scoped>
    h1 {
        text-transform: none;
    }
    .modal-dialog {
        max-width: 560px;
    }
    .modal-content {
        padding: 25px 40px;
    }

    #email_input, #name {
        bottom: -10px;
        font-size: 9px;
    }

    @media (max-width: 500px) {
        .modal-content {
            padding: 0;
        }
    }
</style>