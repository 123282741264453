<template>
    <div class="container">
        <section class="first-step">
            <div class="balance">
                <p class="balance_title">
                    Баланс
                </p>
                <p class="tex_strong">
                    {{ parseFloat(myBalance).toFixed(cryptos.find(c => c.short_title == 'USDT').count_after_point) }}
                    USDT
                </p>
            </div>
            <div class='wrapper'>
                <div class='d-flex justify-content-between align-items-center' :class="{
                        'mb-7': (step > 1 && step < 3 && (type == 'Продажа' || type == 'Покупка')),
                        'mb_38': !(step > 1 && step < 3 && (type == 'Продажа' || type == 'Покупка')),
                        'mobile_final_title': step > 2
                    }">
                    <h5 v-if="step < 2" class='tex_strong first_mob_title m-0'>Выберите тип операции с наличными</h5>
                    <h5 v-if="step > 1 && step < 3 && type == 'Продажа'" class='tex_strong m-0'>Продать за наличные</h5>
                    <h5 v-if="step > 1 && step < 3 && type == 'Покупка'" class='tex_strong m-0'>Купить за наличные</h5>
                    <h5 v-if="step > 2 && type == 'Продажа'" class='tex_strong m-0 mob_code'>Код выдачи</h5>
                    <h5 v-if="step > 2 && type == 'Покупка'" class='tex_strong success_title m-0'>Вы успешно <br>
                        оформили заявку</h5>
                    <button style="height:fit-content" v-if="step > 1 && step < 3" class='arrow-button' type='button'
                        @click='prev'>
                        <img src="/img/ico/white-arrow.svg" alt="стрелка влево" class="me-1">
                        <span>Назад</span>
                    </button>
                    <div class="d-flex gap-2 align-items-center slim_green_text" v-if="step > 2 && type == 'Продажа'">
                        {{ form.amountUSDT }} USDT<img src="/static/img/cash-exchange/Arrow.svg">{{ form.amount }}
                        {{ currency }}</div>
                    <div class="d-flex gap-2 align-items-center slim_green_text" v-if="step > 2 && type == 'Покупка'">
                        {{ form.amount }} {{ currency }}<img src="/static/img/cash-exchange/Arrow.svg">{{ form.amountUSDT }}
                        USDT</div>
                </div>
                <div class='cash-body' v-if='step === 1'>
                    <div style='text-align: left; min-height: 30px;' v-if="errorText">
                        <span class='me-3' style='font-size: 16px; color: red'>{{ errorText }}</span>
                    </div>
                    <div style="margin-top: 50px;">
                        <div class="row" style="row-gap:38px">
                            <div class="col-12 col-sm-6"
                                style="display:flex; flex-direction:column; flex-wrap: wrap; justify-content: center; align-items: center">
                                <img src="/img/fromCasheToCrypto.svg" alt="Купить за наличные">
                                <button @click="buyCash" class='button button--full-width'
                                    style="margin-top: 20px;">Купить за наличные</button>
                            </div>
                            <div class="col-12 col-sm-6"
                                style="display:flex; flex-direction:column; flex-wrap: wrap; justify-content: center; align-items: center">
                                <img src="/img/fromCryptoToCache.svg" alt="Продать за наличные">
                                <button @click="sellCash" class='button button--full-width'
                                    style="margin-top: 20px;">Продать за наличные</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='cash-body' v-else-if='step == 2'>
                    <div class="row mb-4 mb-sm-2">
                        <div class="col-12 col-md-3 mobile_title">
                            <span v-if="type == 'Покупка'">В каком городе хотите <br> купить за наличные?</span>
                            <span v-else>В каком городе хотите <br> продать за наличные?</span>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="row">
                                <div class="col-6 col-md-4">
                                    <label @click="newCity" class='control control-radio'>
                                        Тверь
                                        <input :id="'c1'" type='radio' v-model='city' :value="'Тверь'" />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                                <div class="col-6 col-md-4">
                                    <label @click="newCity" class='control control-radio'>
                                        Москва
                                        <input :id="'c2'" v-model='city' :value="'Москва'" type="radio" />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                        <div class="col-12 col-md-3 mobile_title">
                            <span v-if="type == 'Покупка'">Отдаёте валюту</span>
                            <span v-else>Получаете валюту</span>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="row">
                                <div v-for="cur in currencies" class="col-6 col-md-4">
                                    <label class='control control-radio' style="margin-right: 10px;">
                                        {{ cur }}
                                        <input @change="upd('usdt')" :id="cur" type='radio' v-model='currency'
                                            :value="cur" />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                            </div>
                            <p class="text_info">Обратите внимание - можно создать 1 заявку
                                раз в 30 минут, в случае подозрительной активности аккаунт будет заблокирован.</p>
                        </div>
                    </div>
                    <div v-if="type == 'Продажа'" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span>Сумма получения</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <input id="cashExchange_form_amount_sale" @keyup="upd('cur')" class='inputAmount'
                                style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                v-model='form.amount' min="500" max="200000" autocomplete='off'
                                placeholder='Кол-во валюты' />
                            <p class="m-0 text_info">Доступный баланс: {{ parseFloat(myBalance).toFixed(cryptos.find(c => c.short_title == 'USDT').count_after_point) }} USDT</p>
                            <div class="error-block-text" v-show="errorInput != ''">
                                <p>{{ errorInput }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="type == 'Продажа'" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span>Сумма USDT</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <input id="cashExchange_form_amountUSDT_sale" class='inputAmount' @keyup="upd('usdt')"
                                style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                v-model='form.amountUSDT' autocomplete='off'
                                placeholder='Кол-во валюты' />
                            <p class="m-0 text_info">В том числе комиссия 1%</p>
                        </div>
                    </div>
                    <div v-if="type == 'Покупка'" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span>Сумма USDT</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <input id="cashExchange_form_amountUSDT_buy" class='inputAmount' @keyup="upd('usdt')"
                                style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                v-model='form.amountUSDT' autocomplete='off'
                                placeholder='Кол-во валюты' />
                        </div>
                    </div>
                    <div v-if="type == 'Покупка'" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span>Отдаёте наличными</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <input id="cashExchange_form_amount_buy" @keyup="upd('cur')" class='inputAmount'
                                style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                v-model='form.amount' min="500" max="200000" autocomplete='off'
                                placeholder='Кол-во валюты' />
                            <p class="text_info">В том числе комиссия 1%</p>
                            <div class="error-block-text" v-show="errorInput != ''">
                                <p>{{ errorInput }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4 mb-sm-2">
                        <div class="col-12 col-md-3 mb-2">
                            <span>Способ получения</span>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="row">
                                <div v-if="city != 'Ташкент'" class="col-6 col-md-4">
                                    <label class='control control-radio'>
                                        В офисе
                                        <input :id="'d1'" type='radio' v-model='delivery' :value="'В офисе'" />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                                <div class="col-6 col-md-4">
                                    <label class='control control-radio'>
                                        Доставка
                                        <input :id="'d2'" v-model='delivery' :value="'Доставка курьером'"
                                            type="radio" />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="delivery != 'В офисе'" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span v-if="delivery == 'В офисе'">Адрес офиса</span>
                            <span v-else>Адрес доставки</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <textarea style="width: 100%; border-radius: 5px; margin-top: 0px; cursor: text "
                                class="inputAmount" v-model="address" placeholder="Введите Ваш адрес"></textarea>
                        </div>
                    </div>
                    <div class="error-block-text" v-show="delivery == 'В офисе'">
                        <p>В данный момент, работает только доставка</p>
                    </div>
                    <div v-if="delivery != 'В офисе'" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span>Выберите дату</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <input required class="inputAmount" type="date" id="date" @change="validate" :min="today"
                                style="width: 100%; border-radius: 5px; margin-top: 0px; cursor: pointer "
                                v-model='date' placeholder="Выберите дату" :class="date !== '' ? 'active' : ''">
                            <div class="error-block-text" v-show="errorData != ''">
                                <p>{{ errorData }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="delivery != 'В офисе' && date != ''" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span>Забронировать время</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <select class="inputAmount text-dark"
                                style="width: 100%; border-radius: 5px; margin-top: 0px;" v-model='time'>
                                <option disabled='' selected='' value="">Выберите время</option>
                                <option v-for="(active, time) in officeTime" :disabled="active == 1 ? false : true">
                                    {{ time }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="delivery != 'В офисе'" class="row mb-4 mb-sm-2">
                        <div class="col-12 col-sm-3 mobile_title_15">
                            <span>Контактные данные</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <input class="inputAmount" placeholder="Телеграм или телефон"
                                style="width: 100%; border-radius: 5px; margin-top: 0px;" v-model='contact'>
                        </div>
                    </div>
                    <span style="margin-top: 30px; color: rgb(172, 175, 255);"
                        v-if="type == 'Продажа' && user.status.id != 3">Пройдите <span @click="showVerification"
                            class="text-light cursor-pointer">верификацию</span> для совершения продажи за
                        наличные</span>
                    <div class="row" v-else>
                        <div class="col-3 d-none d-sm-block"></div>

                        <div class="col-12 col-sm-4">
                            <button @click="next"
                                :disabled="address == '' || errorData != '' || date == '' || contact == '' || time == '' || delivery == 'В офисе'"
                                style="margin-top: 30px; width: 100%" class='button button--full-width'>{{ type ==
                        'Продажа' ? 'Получить код выдачи' : 'Оформить заявку' }}</button>
                        </div>
                    </div>
                </div>
                <div class='cash-body' v-if="step > 2 && type == 'Продажа'">
                    <div
                        style="display:flex; width:100%; flex-direction:column; justify-content: space-between; align-items: flex-start; min-height: 500px;">
                        <div class="mb-5 mb-sm-0 min-height-mob"
                            style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                            <p class="text_info" v-if="currency == 'USD'">Обратите внимание -
                                можно создать 1 заявку раз в 30 минут, в случае подозрительной активности аккаунт
                                будет заблокирован.</p>
                        </div>
                        <div class="row w-100 ">
                            <div class="col-12 col-md-4 mb-4 mb-sm-0" style="display:flex; flex-direction:column;">
                                <span class="bold">Адрес получения:</span>
                                <span style="font-size: 16px">{{ address }}</span>
                            </div>
                            <div class="col-12 col-md-6 mb-4 mb-sm-0" style="display:flex; flex-direction:column;">
                                <span class="bold">Дата и время выдачи</span>
                                <span class="text-nowrap" style="font-size: 16px">{{ moment(date).format("DD-MM-YYYY")
                                    }} в {{ time }} МСК</span>
                            </div>
                        </div>
                        <div class="mb-4 mb-sm-0"
                            style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                            <span class="bold">Сумма наличных</span>
                            <span style="font-size: 16px">{{ form.amount }} {{ currency }}</span>
                        </div>
                        <button @click="close" class='button mb-4 mb-sm-0'>Хорошо</button>
                        <div class="code-field">
                            <div class="d-flex flex-column align-items-center">
                                <span class="code-field__title">Код выдачи</span>
                                <span class="code-field__code">{{ code }}</span>
                            </div>
                            <div class="d-block position-relative">
                                <button class="cash-button" @click="copyCard">
                                    <img src="/img/ico/ico-copy.svg" style="margin-right: 5px;" width="12" height="15" alt="Скопировать">
                                    <span @click="changeText">Скопировать</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='cash-body' v-if="step > 2 && type == 'Покупка'">
                    <div
                        style="display:flex; width:100%; flex-direction:column; justify-content: space-between; align-items: flex-start; min-height: 340px;">
                        <div class="mb-5 mb-sm-0"
                            style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                            <p class="text_info" style="font-size:16px;">Обратите внимание - можно создать 1 заявку
                                раз в 30 минут, в случае подозрительной активности аккаунт будет заблокирован.</p>
                        </div>
                        <div class="row w-100">
                            <div class="col-12 col-md-4  mobile_title_15"
                                style="display:flex; flex-direction:column; justify-content: center; align-items: flex-start">
                                <span>Курьер приедет по адресу:</span>
                                <span style="font-size: 16px">{{ address }}</span>
                            </div>
                            <div class="col-12 col-md  mobile_title_15"
                                style="display:flex; flex-direction:column; justify-content: center; align-items: flex-start">
                                <span>Дата и время встречи</span>
                                <span style="font-size: 16px">{{ moment(date).format("DD-MM-YYYY") }} в {{ time }}
                                    МСК</span>
                            </div>
                        </div>
                        <div
                            style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                            <span>Сумма наличных</span>
                            <span style="font-size: 16px">{{ form.amount }} {{ currency }}</span>
                        </div>
                        <button @click="close" style="margin-top: 30px;" class='button'>Хорошо</button>
                    </div>
                </div>
            </div>
        </section>
        <div v-if="cashHistory.length == 0">
            <p class="text-light fz-16 no-active text-center p-3">Вы не совершали операций с наличными</p>
        </div>
        <HistoryComponent v-else
        :user="user"
        :cards="cards"
        :wallets="wallets"
        :cards_count="cards_count"
        :wallets_count="wallets_count"
        :user_load="user_load"
        :balances="balances"
        :cryptos="cryptos"
        :checks="checks"
        :page_one_count="page_one_count"
        :page_lot_count="page_lot_count"
        :page_one="page_one"
        :page_lot="page_lot"
        :for_buy="for_buy"
        :for_deposit="for_deposit"
        :crypto="cryptos"
        :elements="cashHistory"
        :page_count="pageCount"
        :history_load="onLoadHistory"
        :currencies="currenciess"
      />
       
    </div>
</template>

<script>
import HistoryComponent from "@/components/HistoryComponent.vue";
import moment from "moment";
export default {
    props: ['cards', 'wallets', 'courses', 'balances', 'user', 'courses_list', 'currenciess', 'cryptos', 'history', "user_load", "pageCount", "onLoadHistory",  "page_one_count", "page_lot_count",
    "bank_list", 'page_lot',  "for_buy", "for_deposit",],
    components: {
        HistoryComponent,
    },
    created() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        this.today = yyyy + '-' + mm + '-' + dd;

        if(this.myAction && this.myAction == 'sale') {
            this.sellCash();
        }
        else if(this.myAction && this.myAction == 'buy') {
            this.buyCash();
        }
    },
    data: () => ({
        page: 1,
        perPage: 10,
        step: 1,
        myHistory: [],
        moment: moment,
        tipe: 'text',
        selectedDate: null,
        form: {
            bank: 0,
            amount: 0,
            amountUSDT: 0,
            file: '',
            cardNumber: '',
        },
        type: '',
        address: '',
        date: '',
        today: new Date(),
        time: '',
        currency: 'USD',
        logo: '',
        contact: '',
        city: 'Тверь',
        delivery: 'Доставка курьером',
        ourCard: 'XXXX XXXX XXXX XXXX',
        title: 'cashTether',
        errorText: '',
        currencies: {
            0: 'USD',
            1: 'RUB',
        },
        placeHolder: '',
        commission: 0.01,
        errorInput: '',
        errorData: '',
        cash_buy: false,
        cash_sale: false,
        code: '',
        past: 'Прошедшая дата',
        good: 'Дата указана верно',
        officeTime: {
            '10:00-10:30': 1,
            '10:30-11:00': 1,
            '11:00-11:30': 1,
            '11:30-12:00': 1,
            '12:00-12:30': 1,
            '12:30-13:00': 1,
            '13:00-13:30': 1,
            '13:30-14:00': 1,
            '14:00-14:30': 1,
            '14:30-15:00': 1,
            '15:00-15:30': 1,
            '15:30-16:00': 1,
            '16:00-16:30': 1,
            '16:30-17:00': 1,
            '17:00-17:30': 1,
            '17:30-18:00': 1,
            '18:00-18:30': 1,
            '18:30-19:00': 1,
            '19:00-19:30': 1,
            '19:30-20:00': 1,
            '20:00-20:30': 1,
            '20:30-21:00': 1,
            '21:00-21:30': 1,
            '21:30-22:00': 1,
            '22:00-22:30': 1,
            '22:30-23:00': 1,
        },
    }),
    watch: {
        cash_buy: {
            handler() {
                if (this.cash_buy != false) {
                    this.buyCash();
                }
            },
        },
        cash_sale: {
            handler() {
                if (this.cash_sale != false) {
                    this.sellCash();
                }
            },
        },
        date: {
            handler() {
                const data = new Date(this.date);
                const today = new Date(this.today);
                console.log(data);
                console.log(today);
                if (data.getTime() == today.getTime()) {
                    console.log(1);
                    Object.entries(this.officeTime).forEach((num) => {
                        if (this.checkTime(num[0]) == true) {
                            this.officeTime[num[0]] = 1
                        } else {
                            this.officeTime[num[0]] = 0;
                        }
                    });
                } else {
                    Object.entries(this.officeTime).forEach((num) => {
                        this.officeTime[num[0]] = 1
                    });
                }
            }
        }
    },
    computed: {
        crypto() {
            return this.cryptos.find(c => c.short_title === 'USDT')
        },
        timeNow() {
            const today = new Date();
            let hours = today.getHours();
            let minutes = today.getMinutes();
            return hours + ':' + minutes;
        },
        myBalance() {
            return this.balances && this.balances.length !== 0 ? this.balances.find(b => b.title === 'USDT').value : 0;
        },
        cashHistory() {
            let cashHistory = [];
            this.history.forEach((element) => {
                if (element.operation.operation_title === 'Наличные') {
                    cashHistory.push(element);
                }
            });
            return cashHistory;
        },
        myAction() {
            return this.$route.query.action;
        }

    },
    methods: {
        showVerification() {
            console.log('Передано');
            this.$emit('showVerification');
        },
        checkTime(val) {
            let time = this.timeNow.split(':');
            let diapasone = val.split('-');
            let value = diapasone[0].split(':');
            if (parseInt(time[0]) > parseInt(value[0])) {
                return false;
            } else {
                if (parseInt(time[0]) == parseInt(value[0]) && parseInt(time[1]) > parseInt(value[1])) {
                    return false;
                } else {
                    return true;
                }

            }
        },
        updBuy() {
            if (this.cash_buy) {
                this.step = 2;
                this.type = 'Покупка';
            }
        },
        copyCard() {
            navigator.clipboard.writeText(this.code);
        },

        changeText(event) {
                const originalText = event.target.textContent;
                event.target.textContent = 'Скопировано!';
                setTimeout(() => {
                    event.target.textContent = originalText;
                }, 1000);
            },

        currencyCourseBuy() {
            if (this.courses_list && this.courses_list['Покупка']) {
                let course = this.courses_list['Покупка'].find(c => c.cryptocurrency_id_from === this.crypto.id
                    && c.currency_id_to === this.currenciess.find(c => c.short_title === this.currency).id).rate;
                console.log('course', course);
                return parseFloat(course).toFixed(2);
            } else {
                return 1;
            }
        },

        currencyCourseSale() {
            if (this.courses_list && this.courses_list['Продажа']) {
                let course = this.courses_list['Продажа'].find(c => c.cryptocurrency_id_from === this.crypto.id
                    && c.currency_id_to === this.currenciess.find(c => c.short_title === this.currency).id).rate;
                console.log('course', course);
                return parseFloat(course).toFixed(2);
            } else {
                return 1;
            }
        },

        upd(key) {
            let course = { 0: 1, 1: 1 };
            // if(this.currency == 'RUB') {
            //     course = this.courses_list;
            // }
            if (key == 'usdt' && this.type == 'Покупка') {
                this.form.amount = (this.currencyCourseBuy() * (parseFloat(this.form.amountUSDT) + parseFloat(this.form.amountUSDT) * this.commission)).toFixed(2);
                setTimeout(() => {
                    if (this.currency == 'RUB') {
                        this.form.amount = Math.round(this.form.amount / 100) * 100;
                    } else {
                        this.form.amount = Math.round(this.form.amount);
                    }
                }, 2500);
            }
            if (key == 'cur' && this.type == 'Покупка') {
                this.form.amountUSDT = ((parseFloat(this.form.amount) / (1 + this.commission)) / this.currencyCourseBuy()).toFixed(0);
                setTimeout(() => {
                    if (this.currency == 'RUB') {
                        this.form.amount = Math.round(this.form.amount / 100) * 100;
                    } else {
                        this.form.amount = Math.round(this.form.amount);
                    }
                }, 2500);
            }
            if (key == 'usdt' && this.type == 'Продажа') {
                this.form.amount = ((parseFloat(this.form.amountUSDT) / (1 + this.commission)) * this.currencyCourseSale()).toFixed(0);
                setTimeout(() => {
                    if (this.currency == 'RUB') {
                        this.form.amount = Math.round(this.form.amount / 100) * 100;
                    } else {
                        this.form.amount = Math.round(this.form.amount);
                    }
                }, 2500);
            }
            if (key == 'cur' && this.type == 'Продажа') {
                this.form.amountUSDT = ((parseFloat(this.form.amount) + parseFloat(this.form.amount) * this.commission) / this.currencyCourseSale()).toFixed(0);
                if (this.currency === 'RUB') {
                    setTimeout(() => {
                        this.form.amount = Math.round(this.form.amount / 100) * 100;
                    }, 2500);
                } else {
                    this.form.amount = Math.round(this.form.amount);
                }
            }
            if (this.currency == 'USD') {
                if (this.form.amountUSDT < 500 && this.city == 'Тверь') {
                    this.errorInput = 'Минимальная сумма покупки 500 USDT';
                    return;
                } else {
                    this.errorInput = ''
                }
                if (this.form.amountUSDT < 5000 && this.city == 'Москва') {
                    this.errorInput = 'Минимальная сумма покупки 5000 USDT';
                    return;
                } else {
                    this.errorInput = ''
                }
                if (this.form.amountUSDT > 200000) {
                    this.errorInput = 'Максимальная сумма покупки 200 000 USDT';
                    return;
                } else {
                    this.errorInput = ''
                }
            } else {
                if (this.form.amount < 50000) {
                    this.errorInput = 'Минимальная сумма покупки 50 000 RUB';
                    return;
                } else {
                    this.errorInput = ''
                }
                if (this.form.amount > 20000000) {
                    this.errorInput = 'Максимальная сумма покупки 20 000 000 RUB';
                    return;
                } else {
                    this.errorInput = ''
                }
            }

            if (parseFloat(this.form.amountUSDT) > parseFloat(this.balance) && this.type == 'Продажа') {
                this.errorInput = 'Недостаточно средств';
                return;
            }
        },
        validate() {
            const date = new Date(this.date);
            const today = new Date(this.today);
            if (date.getTime() < today.getTime()) {
                this.errorData = 'Дата не должна быть раньше чем сегодня';
                return false;
            } else {
                this.errorData = '';
                return true;
            }
        },
        next() {
            if (this.step == 2) {
                let chrs = 'ABDEFGHKMNPQRSTWXZ123456789';
                var str = '';
                for (var i = 0; i < 9; i++) {
                    var pos = Math.floor(Math.random() * chrs.length);
                    str += chrs.substring(pos, pos + 1);
                }
                this.code = str;
                let course = { 0: 1, 1: 1 };
                if (this.currency == 'RUB') {
                    course = this.courses;
                }
                let data = {
                    amount: this.form.amountUSDT,
                    city: this.city,
                    address: this.address,
                    date: this.date,
                    time: this.time,
                    contact: this.contact,
                    // commission: this.commission,
                    delivery_type: this.delivery == 'Доставка курьером' ? 'Доставка' : 'Самовывоз',
                    currency_id: this.currency == 'USD' ? 4 : 1,
                    crypto_id: 1,
                    operation_type: this.type,
                    user_id: this.$user_ID.value,
                    token: sessionStorage.getItem('user_token'),
                    code: this.code,
                    // course: course
                }
                if (this.type == 'Продажа') {
                    data.code = this.code;
                }
                // console.log(data);
                let resp = '';
                axios.post(this.$api_address + '/cashes', data).then(response => {
                    console.log(response.data);
                    if (response.data.error) {
                        this.errorText = resp.error;
                        alert('недостаточно денежных средств');
                        return;
                    } else {
                        this.$emit('cash');
                    }
                });
            }
            this.step++;
        },
        prev() {
            this.step--;
            this.errorInput = '';
            this.date = '';
            this.time = '';
            this.form.amountUSDT = '',
            this.form.amount = ''
        },
        newCity() {
            this.address = '';
        },
        close(event) {
            this.address = '';
            this.date = null;
            this.contact = null;
            this.time = '';
            this.step = 1;
            this.form.amount = null;
            this.form.amountUSDT = null;
        },
        buyCash() {
            this.step = 2;
            this.type = 'Покупка';
        },
        sellCash() {
            this.type = 'Продажа';
            this.step = 2;
        }
    },
}
</script>

<style scoped>
* {
    color: #FFF;
}

.no-active {
    border-radius: 5px;
    border: 1px solid #35634F;
    font-size: 20px;
    line-height: normal;
}

input,
textarea {
    border: 1px solid #fff;
    background: transparent;
}

textarea {
    padding-top: 7px;
}

.button {
    background: #4AA485;
    cursor: pointer;
}

hr {
    background: #2C463B;
    color: #2C463B;
    margin: 38px 0;
}

::-webkit-input-placeholder {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.35;
}

::-moz-placeholder {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.35;
}

:-moz-placeholder {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.35;
}

:-ms-input-placeholder {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.35;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
    outline: none;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:focus,
input:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:focus {
    appearance: none;
    background-image: none !important;
    background-color: transparent !important;
    color: #fff !important;
}

.wrapper {
    background: #35634F;
    padding: 35px 18px;
    border-radius: 5px;
    margin-bottom: 85px;
}

.balance {
    padding: 20px;
    margin: 30px 0;
    border-radius: 5px;
    border: 1px solid #35634F;
    background: 100%/cover no-repeat url(/public/static/img/cash-exchange/balance.jpg);
}

.balance_title {
    margin-bottom: 9px;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tex_strong {
    max-width: 418.217px;
    margin-bottom: 20px;
    font-family: Montserrat;
    font-size: 30.5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.cash-button {
    position: absolute;
    bottom: 20px;
    right: 0;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-decoration: underline;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[type="date"] {
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

input[type="date"]:after {
    content: url(/public/static/img/cash-exchange/Polygon.svg);
    position: absolute;
    right: 20px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  select::-ms-expand {
    display: none;
  }

option,
select {
    background-color: #35634F;
    color: #fff !important;
    cursor: pointer;

}

select {
    border: 1px solid #fff;
    border-radius: 5px;
    background: url(/public/static/img/cash-exchange/Polygon.svg) no-repeat right;
    background-position-x: calc(100% - 20px);
}

.mb-7 {
    margin-bottom: 67px;
}

.code-field {
    padding: 14px 38px 9px;
    width: 100%;
    text-align: center;
    background: left/cover url(/public/static/img/cash-exchange/code-field.jpg);
}

.text_info {
    color: #69E278;
    font-size: 14px;
    max-width: 600px;
    margin: 0;
}

.mb_38 {
    margin-bottom: 38px;
}

.bold {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.slim_green_text {
    color: #69E278;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: fit-content;
}

.code-field__title {
    font-size: 20px;
}

.code-field__code {
    font-size: 60px;
}

.accordion-button:not(.collapsed)::after, .accordion-button::after {
    background-image: url(/public/static/img/ico/ico-accordeon.svg);
    width: 30px;
    height: 15px;
}

.danger {
    color: #FF6D6D;
}

.success {
    color: #74ED79;
}

.active_pays_header {
    width: 90%;
}

.active_pays_header :not(:first-child) {
    font-size: 16px;
}

#active_pays_accordeon {
    display: flex;
    flex-direction: column;
    gap: 15px;
}



input[type="date"]:before {
    content: attr(placeholder);
    position: absolute;
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.35;
  }
  input[type="date"].active:before {
    content: none;
  }

  input[type=date]:not(.active)::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
}
input[type=date]:not(.active)::-webkit-datetime-edit-month-field{
    -webkit-appearance: none;
    display: none;
}
input[type=date]:not(.active)::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
}
input[type=date]:not(.active)::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
} 

@media(max-width:500px) {
    h5.tex_strong {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .first_mob_title {
        max-width: 206px;
    }

    .mb-7 {
        margin-bottom: 25px;
    }

    hr {
        display: none;
    }

    ::-webkit-input-placeholder {
        font-size: 16px;
    }

    ::-moz-placeholder {
        font-size: 16px;
    }

    :-moz-placeholder {
        font-size: 16px;
    }

    :-ms-input-placeholder {
        font-size: 16px;
    }

    .mobile_title {
        margin-bottom: 20px;
    }

    .mobile_title_15 {
        margin-bottom: 15px;
    }

    .slim_green_text {
        font-size: 16px;
    }

    .mobile_final_title {
        flex-direction: column !important;
        justify-content: start !important;
        align-items: flex-start !important;
        gap: 10px;
    }

    .success_title br {
        display: none;
    }

    .success_title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .code-field__title {
        font-size: 16px;
    }

    .code-field__code {
        font-size: 36px;
    }

    .cash-button {
        position: unset;
        margin: 0 auto;
    }

    .bold {
        font-size: 16px;
    }

    .mob_code {
        font-size: 20px;
    }

    .min-height-mob {
        min-height: unset !important;
    }
}

@media(max-width:375px) {
    .success_title {
        font-size: 16px;
    }
}
</style>