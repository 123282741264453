<template>
    <fragment>
    <section class="first_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9 z-1 order-2 order-md-1">
                    <div class="left">
                        <div class="mb-4"><span class="rounded border_green px-3 py-3 undertitle">Услуга доступна <b>только для зарегистрированных</b> пользователей. </span></div>
                        <h1 class="mb-3"><span class="text-green">Оплата</span> по ссылке</h1>
                        <div class="sub_subtitle">
                            <p>Как оплатить товар или услугу в европейском банке?</p>
                            <p>Теперь такая функция доступна в Криптобанке Valutix благодаря функции <b>Оплата по ссылке</b>.</p>
                            <p>Так как Visa и Mastercard российских банков не работают при оплате на зарубежных сайтах,
                                 мы добавили данный функционал для наших пользователей. </p>
                        </div>
                        <router-link  exact class="myButton"  to="/registration">Зарегистрироваться</router-link>
                    </div>
                    <div class="right">

                    </div>
                </div>
                <div class="col-12 col-md-3 d-flex justify-content-end justify-content-md-start order-1 order-md-2">
                    <img src="/static/img/pay-link/first_section.png" class="first_img z-0">
                </div>
            </div>
        </div>
    </section>

    <section class="who_can_use_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-5 order-2 order-lg-1 me-auto  mt-5 mt-lg-0 d-block">
                    <img src="/static/img/pay-link/second_section.png" class="d-block mx-auto">
                </div>
                <div class="col-12 col-lg-5 order-1 order-lg-2">
                    <h2>Кому подходит <br> данное решение?</h2>
                    <ul class="custom_ul p-0">
                        <li>Предпринимателям для расчета с заграничными контрагентами</li>
                        <li>Пользователям для покупки товара или услуги перед транспортировкой из EU юрисдикции</li>
                        <li>Пользователям для оплаты в облачных магазинах (ex.: spotify, PS, Xbox и тд.)</li>
                    </ul>
                    <b class="fs-5 d-block mb_30">Уже зарегистрированы?</b>
                    <router-link exact class="myButton text-nowrap"  to="/login">Войти в ЛК</router-link>
                </div>
            </div>
        </div>
    </section>

    <section class="second_section">
        <div class="container">
            <h2>Всего несколько шагов для клиента:</h2>
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <div class="wrap"><img width="162" src="/static/img/pay-link/wallet.png"></div>
                        <h6>Шаг 1</h6>
                        <span>
                            Пополняем внутренний баланс в Личном кабинете криптобанка Valutix банковской картой, криптовалютой или функцией “Счет”
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <div class="wrap"><img width="162" src="/static/img/pay-link/blockchain.png"></div>
                        <h6>Шаг 2</h6>
                        <span>
                            Присылаем банковскую ссылку на оплату товара/услуги.<br><br>

                            (Подробная инструкция на странице https://valutix.io/pay-link)
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <div class="wrap"><img width="140" src="/static/img/pay-link/secure.png"></div>
                        <h6>Шаг 3</h6>
                        <span>
                            Система оплачивает ваш товар/услугу по ссылке, средства списываются с вашего ЛК. <br><br> Готово!
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </fragment>
</template>

<script>
export default {
name: 'Pay-link',
}
</script>

<style scoped>
h1 {
    max-width: 788px;
    font-size: 70.5px;
    font-weight: 700;
    line-height: 90.49%;
    text-transform: none;
}

h2, .h2 {
    font-size: 30.5px;
    font-weight: 600;
    margin-bottom: 20px;
}

h6 {
    font-size: 20px;
    font-weight: 600;
}

.sub_subtitle {
display: inline-block;
max-width: 558px;
margin-bottom: 50px;
}

.first_section {
padding: 116px 0 0;
}

.first_img {
margin-left: -100px;
margin-top: -100px;
}

.card {
padding: 25px;
color: #fff;
height: 100%;
}

/* .card img {
margin: -140px auto 60px;
} */

.wrap {
height: 169px;
display: flex;
justify-content: center;
align-items: flex-end;
margin: -134px auto 0;
}

.second_section {
padding: 0 0 121px;
}

.second_section h2 {
margin-bottom: 120px;
}

.header_block {
margin-bottom: 55px;
}

.border_green {
border: 1px solid #35634F;
}

.who_can_use_section {
padding: 127px 0 180px;
background: bottom -100px left no-repeat url(/public/static/img/invoice_page/gradient.png)
}

.custom_ul li:not(:last-child) {
margin-bottom: 23px;
}

.custom_ul {
margin-bottom: 40px;
}

.custom_ul li {
align-items: start;
}

.custom_ul li:before {
margin-top: 5px;
}


@media(max-width: 768px) {
.second_section .row {
    gap: 144px;
}

.card img {
    margin: 0 auto;
}

.first_img{
    margin-left: unset;
    margin-top: unset;
    margin-right: -100px;
    margin-bottom: -100px;
    max-width: 374px;
}

.undertitle {
    border: none;
    padding: 0 !important;
}

section {
    padding: 50px 0 50px;
}

.who_can_use_section {
    padding: 50px 0;
}

.who_can_use_section img {
    width: 299px;
}

}

@media(max-width: 500px) {
h1 {
    font-size: 30.5px;
    max-width: 232px;
}

.container {
    padding-right: 38px;
    padding-left: 38px;
}

.subtitle {
    margin-bottom: 35px;
}

.first_section {
    padding: 100px 0 50px;
}

.second_section .container {
    padding: 0 18px;
}

.second_section {
    padding-bottom: 50px;
}

.header_block {
    margin-bottom: 32px;
}

}
</style>