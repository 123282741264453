<template>
    <fragment>
    <section class="first_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9 z-1 order-2 order-md-1">
                    <div class="left">
                        <h1 class="mb-3"><span class="text-green">Инвойс</span><br class="d-none d-sm-block"> в криптовалюте</h1>
                        <span class="sub_subtitle">
                            Вы можете настроить оплату услуг и товаров от клиентов в криптовалюте, с карт иностранных государств, благодаря функции выставления <b>единоразового</b> и <b>многоразового</b> счета. Платежи через сайт, прямые ссылки и QR-код для вашей online площадки или offline точки.
                        </span>
                        <button class="myButton"  data-bs-toggle="modal" data-bs-target="#supportModal">Подключить</button>
                    </div>
                    <div class="right">

                    </div>
                </div>
                <div class="col-12 col-md-3 d-flex justify-content-end justify-content-md-start order-1 order-md-2">
                    <img src="/static/img/invoice_page/first_section.png" class="first_img z-0">
                </div>
            </div>
        </div>
    </section>

    <section class="who_can_use_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-5 order-2 order-lg-1 me-auto  mt-5 mt-lg-0 d-block">
                    <img src="/static/img/invoice_page/second_section.png" class="d-block mx-auto">
                </div>
                <div class="col-12 col-lg-5 order-1 order-lg-2">
                    <h2>Кому подходит <br> данное решение?</h2>
                    <ul class="custom_ul p-0">
                        <li>Remote работникам сферы услуг и фрилансерам</li>
                        <li>Владельцам розничного бизнеса или сферы услуг (как online, так и offline точек)</li>
                        <li>Клиентам за границей с необходимостью трансграничных переводов</li>
                    </ul>
                    <a target="_blank" href="https://faq.valutix.io/knowledgebase/how-to-use-counting-feature/" class="myButton text-nowrap">Как это работает?</a>
                </div>
            </div>
        </div>
    </section>

    <section class="second_section">
        <div class="container">
            <h2>Всего несколько шагов для клиента:</h2>
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <div class="wrap"><img width="116" src="/static/img/invoice_page/Secured.png"></div>
                        <h6>Шаг 1</h6>
                        <span>
                            Нажимает кнопку “Оплатить сейчас” на нужный товар или переходит по ссылке, QR
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <div class="wrap"><img width="152" src="/static/img/invoice_page/step-2.png"></div>
                        <h6>Шаг 2</h6>
                        <span>
                            Производит оплату на платежной форме по нужным реквизитам.
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border_green bg-transparent">
                        <div class="wrap"><img width="169" src="/static/img/invoice_page/step-3.png"></div>
                        <h6>Шаг 3</h6>
                        <span>
                            Получает мгновенно уведомление об успешной операции, а вы средства в ваш ЛК.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </fragment>
</template>

<script>
export default {
name: 'Invoice',
}
</script>

<style scoped>
h1 {
    max-width: 788px;
    font-size: 70.5px;
    font-weight: 700;
    line-height: 90.49%;
    text-transform: none;
}

h2, .h2 {
    font-size: 30.5px;
    font-weight: 600;
    margin-bottom: 20px;
}

h6 {
    font-size: 20px;
    font-weight: 600;
}

.sub_subtitle {
display: inline-block;
max-width: 558px;
margin-bottom: 50px;
}

.first_section {
padding: 116px 0 0;
}

.first_img {
margin-left: -100px;
margin-top: -100px;
}

.card {
padding: 27px;
color: #fff;
height: 100%;
}

/* .card img {
margin: -140px auto 60px;
} */

.wrap {
height: 169px;
display: flex;
justify-content: center;
align-items: flex-end;
margin: -134px auto 0;
}


.second_section {
padding: 0 0 121px;
}

.second_section h2 {
margin-bottom: 120px;
}

.header_block {
margin-bottom: 55px;
}

.border_green {
border: 1px solid #35634F;
}

.who_can_use_section {
padding: 127px 0 180px;
background: bottom -200px left no-repeat url(/public/static/img/invoice_page/gradient.png)
}

.custom_ul li:not(:last-child) {
margin-bottom: 23px;
}

.custom_ul {
margin-bottom: 40px;
}

.custom_ul li {
align-items: start;
}

.custom_ul li:before {
margin-top: 5px;
}


@media(max-width: 768px) {
.second_section .row {
    gap: 144px;
}

.card img {
    margin: 0 auto;
}

.first_img{
    margin-left: unset;
    margin-top: unset;
    margin-right: -100px;
    margin-bottom: -100px;
    max-width: 374px;
}

section {
    padding: 50px 0 50px;
}

.who_can_use_section {
    padding: 50px 0;
}

.who_can_use_section img {
    width: 299px;
}

}

@media(max-width: 500px) {
h1 {
    font-size: 30.5px;
    max-width: 232px;
}

.container {
    padding-right: 38px;
    padding-left: 38px;
}

.subtitle {
    margin-bottom: 35px;
}

.first_section {
    padding: 100px 0 50px;
}

.second_section .container {
    padding: 0 18px;
}

.second_section {
    padding-bottom: 50px;
}

.header_block {
    margin-bottom: 32px;
}

}
</style>