<template>
    <div id="conversion-id">
        <div class='o-text-primary u-font-weight-600 mb-2'>Конвертация</div>
        <div class='u-bg-secondary h-100 u-border-radius'>
            <div class='lk__convert-wrapper'>
                <button class='lk__convert-ico' @click="swap"><img src='/img/ico/ico-convert--big.svg' /></button>
                <div class='lk__convert gap-2'>
                    <img v-if="crypto1.short_title == 'USDT'" src='/img/ico/ico-tether--color.png' width="32" height="32" />
                    <img v-if="crypto1.short_title == 'BTC'" src='/img/ico/ico-bitcoin--color.png' width="32" height="32" />
                    <img v-if="crypto1.short_title == 'ETH'" src='/img/ico/ico-ethereum--color.png' width="32" height="32" />
                    <img v-if="crypto1.short_title == 'TON'" src='/img/ico/ico-toncoin--color.png' width="32" height="32" />
                    <img v-if="crypto1.short_title == 'BNB'" src='/img/ico/ico-binancecoin--color.png' width="32" height="32" />
                    <img v-if="crypto1.short_title == 'LTC'" src='/img/ico/ico-litecoin--color.png' width="32" height="32" />
                    <select @change="changeCrypto" class='lk__convert' style="width:100px; border-radius:5px;" v-model="crypto1">
                        <option v-for="(crypto_item, index) in crypto" :key="index" :value="crypto_item"
                            :disabled="crypto2.id == crypto_item.id">{{ crypto_item.short_title }}</option>
                    </select>
                    <div class="position-relative">
                        <input type='number' @keyup="calc(1)" class='o-input o-input--small lk__convert-right w-100' v-model="val1" placeholder='Введите сумму' />
                         <div class="position-relative">
                            <span @click="convAllTop" class="convAllBtn">Всё</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class='lk__convert-wrapper'>
                <div class='lk__convert gap-2'>
                    <img v-if="crypto2.short_title == 'USDT'" src='/img/ico/ico-tether--color.png' width="32" height="32" />
                    <img v-if="crypto2.short_title == 'BTC'" src='/img/ico/ico-bitcoin--color.png' width="32" height="32" />
                    <img v-if="crypto2.short_title == 'ETH'" src='/img/ico/ico-ethereum--color.png' width="32" height="32" />
                    <img v-if="crypto2.short_title == 'TON'" src='/img/ico/ico-toncoin--color.png' width="32" height="32" />
                    <img v-if="crypto2.short_title == 'BNB'" src='/img/ico/ico-binancecoin--color.png' width="32" height="32" />
                    <img v-if="crypto2.short_title == 'LTC'" src='/img/ico/ico-litecoin--color.png' width="32" height="32" />
                    <select @change="changeCrypto" class='lk__convert' style="width:100px; border-radius:5px;" v-model="crypto2">
                        <option v-for="(crypto_item, index) in crypto" :key="index" :value="crypto_item"
                            :disabled="crypto1.id == crypto_item.id">{{ crypto_item.short_title }}</option>
                    </select>
                    <div class="position-relative">
                        <input type='number' @keyup="calc(2)" class='o-input o-input--small lk__convert-right w-100' v-model="val2" placeholder='Введите сумму' />
                        <div class="position-relative">
                            <span @click="convAllbottom" class="convAllBtn">Всё</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class='lk__convert-wrapper'>
                <div class='lk__convert'>
                    <div class='lk__convert-right'>Комиссия системы за конвертацию — 0.6%</div>
                    <button :disabled='this.val2 ? false : true' @click="send"
                        class='button lk__convert-left'>Обменять</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['courses', 'crypto', 'balances'],
        data() {
            return {
                usdt: '0.00',
                btc: '0.00000000',
                val1: '0.00',
                val2: '0.00000000',
                comission: 0.006,
                crypto1: [],
                crypto2: [],
            }
        },
        mounted() {
            this.crypto1 = this.crypto && this.crypto.length > 0 ? this.crypto[0] : null;
            this.crypto2 = this.crypto && this.crypto.length > 1 ? this.crypto[1] : null;
        },
        watch: {
            crypto: {
                handler() {
                        this.crypto1 = this.crypto && this.crypto.length > 0 ? this.crypto[0] : null;
                        this.crypto2 = this.crypto && this.crypto.length > 1 ? this.crypto[1] : null;
                    },
            }
        },
        computed: {
            crypto1Start() {
                return this.crypto && this.crypto.length > 0 ? this.crypto[0] : null;
            },
            crypto2Start() {
                return this.crypto && this.crypto.length > 1 ? this.crypto[1] : null;
            },
            roundingCrypto1() {
                let cryptos = Object.values(this.crypto);
                let rounding = cryptos.find(c => c.short_title == this.crypto1.short_title).count_after_point;
                if (rounding) {
                    return rounding;
                } else {
                    return 8;
                }
            },
            roundingCrypto2() {
                let cryptos = Object.values(this.crypto);
                let rounding = cryptos.find(c => c.short_title == this.crypto2.short_title).count_after_point;
                if (rounding) {
                    return rounding;
                } else {
                    return 8;
                }
            },
        },
        methods: {
            findCourse(crypto_id) {
                let course_rate = this.courses['Крипта'].find(course => course.cryptocurrency_id_to == crypto_id).rate;
                console.log('course_rate ', course_rate);
                return course_rate;
            },
            changeCrypto() {
                this.calc(1);
            },
            swap() {
                let tmp = this.crypto1;
                this.crypto1 = this.crypto2;
                this.crypto2 = tmp;
                this.val1 = this.val2;
                this.calc(1);
            },
            calc(pos) {
                if (pos == 1) {
                    if (this.crypto1.id == 1) {
                        this.val2 = (this.val1 / this.findCourse(this.crypto2.id)) - (this.val1 / this.findCourse(this.crypto2.id) * this.comission)
                        setTimeout(() => {
                            if (this.val1.length > this.roundingCrypto1 + 1) {
                                this.val1 = parseFloat(this.val1).toFixed(this.roundingCrypto1);
                            }
                        }, 2500);
                        this.val2 = parseFloat(this.val2).toFixed(this.roundingCrypto2);
                        return;
                    }
                    if (this.crypto2.id == 1) {
                        this.val2 = (this.val1 * this.findCourse(this.crypto1.id)) - (this.val1 * this.findCourse(this.crypto1.id) * this.comission)
                        setTimeout(() => {
                            if (this.val1.length > this.roundingCrypto1 + 1) {
                                this.val1 = parseFloat(this.val1).toFixed(this.roundingCrypto1);
                            }
                        }, 2500);
                        this.val2 = parseFloat(this.val2).toFixed(this.roundingCrypto2);
                        return;

                    }
                    this.val2 = (this.val1 * this.findCourse(this.crypto1.id) / this.findCourse(this.crypto2.id)) - (this.val1 * this.findCourse(this.crypto1.id) / this.findCourse(this.crypto2.id) * this.comission);
                    setTimeout(() => {
                        if (this.val1.length > this.roundingCrypto1 + 1) {
                            this.val1 = parseFloat(this.val1).toFixed(this.roundingCrypto1);
                        }
                    }, 2500);
                    this.val2 = parseFloat(this.val2).toFixed(this.roundingCrypto2);
                    return;

                }
                if (pos == 2) {
                    if (this.crypto2.id == 1) {
                        this.val1 = (this.val2 / (1 - this.comission) / this.findCourse(this.crypto1.id));
                        this.val1 = parseFloat(this.val1).toFixed(this.roundingCrypto1);
                        setTimeout(() => {
                            if (this.val2.length > this.roundingCrypto2 + 1) {
                                this.val2 = parseFloat(this.val2).toFixed(this.roundingCrypto2);
                            }
                        }, 2500);
                        return;
                    }
                    if (this.crypto1.id == 1) {
                        this.val1 = ((this.val2 / (1 - this.comission)) * this.findCourse(this.crypto2.id));
                        this.val1 = parseFloat(this.val1).toFixed(this.roundingCrypto1);
                        setTimeout(() => {
                            if (this.val2.length > this.roundingCrypto2 + 1) {
                                this.val2 = parseFloat(this.val2).toFixed(this.roundingCrypto2);
                            }
                        }, 2500);
                        return;

                    }
                    this.val1 = ((this.val2 / (1 - this.comission)) / this.findCourse(this.crypto1.id) * this.findCourse(this.crypto2.id));
                    this.val1 = parseFloat(this.val1).toFixed(this.roundingCrypto1);
                    setTimeout(() => {
                        if (this.val2.length > this.roundingCrypto2 + 1) {
                            this.val2 = parseFloat(this.val2).toFixed(this.roundingCrypto2);
                        }
                    }, 2500);
                    return;
                
                }
            },
            send() {
                let data = {
                    crypto_id_from: this.crypto.find(c => c.short_title === this.crypto1.short_title).id,
                    crypto_id_to: this.crypto.find(c => c.short_title === this.crypto2.short_title).id,
                    amount: parseFloat(this.val1),
                    user_id: parseFloat(this.$user_ID.value),
                    token: sessionStorage.getItem('user_token'),
                    // val2: this.val2,
                    // commission: this.comission
                };
                axios
                .post(this.$api_address + '/conversions', data, {
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                }).then(response => {
                    if (typeof (response.data.message) != "undefined" && response.data.message !== null) {
                        alert(response.data.message);
                    } else {
                        this.val1 = parseFloat(0).toFixed(this.roundingCrypto1);
                        this.val2 = parseFloat(0).toFixed(this.roundingCrypto2);
                        if (this.$metrika !== undefined) {
                            this.$metrika.reachGoal('convert_success');
                        }
                        this.$emit('convert', data);
                    }
                })
                
            },
            checkCode() {
                let data = { type: 'phone', code: this.codeForMail }
                axios.post('/verification/code', data, {
                }).then(response => {
                    if (response.data.success) {
                        this.isCode = true;
                        this.errorText.phone = '';
                        this.checkALlFields();
                    } else {
                        this.errorText.phone = response.data.error;
                    }
                }).catch(function (error) {
                    this.errorText.phone = 'Что-то пошло не так, попробуйте снова';
                });
            },
            checkPhone() {
                let data = { type: 'phone', code: '' }
                axios.post('/verification/code', data, {
                }).then(response => {
                    this.step = 2;
                }).catch(function (error) {
                    this.errorText.phone = 'Что-то пошло не так, попробуйте снова';
                });
            },
            checkMail() {
                let data = { type: 'mail', code: '' }
                axios.post('/verification/code', data, {
                }).then(response => {
                    this.stepMail = 2;
                }).catch(function (error) {
                    this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
                });
            },
            changePhone() {
                this.isCode = false;
                this.codeForPhone = '';
                this.checkALlFields();
            },
            checkALlFields() {
                if (this.selfi != '' && this.pass1 != '' && this.pass2 != '' && this.fio != '' && this.phone != '' && this.isCode == true) {
                    this.ALlFields = true;
                } else {
                    this.ALlFields = false;
                }
            },
            close(event) {
                this.$emit('close');
            },
            back() {
                this.isChangeMail = false;
            },
            switchBlock() {
                if (this.isChangeMail == false) {
                    this.isChangeMail = true;
                }
            },
            changeMail() {
                let data = { type: 'mail', code: this.codeForMail, mail: this.newMail }
                axios.post('/verification/code', data, {
                }).then(response => {
                    if (response.data.success) {
                        this.isCodeMail = true;
                        this.errorText.mail = '';
                        this.mail = this.newMail;
                        this.newMail = '';
                        this.codeForMail = '';
                        this.stepMail = 1;
                        this.back();
                    } else {
                        this.errorText.mail = response.data.error;
                    }
                }).catch(function (error) {
                    this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
                });

            },
            convAllTop() {
                this.val1 = this.balances.find(b => b.title === this.crypto1.short_title).value;
                this.calc(1);
            },
            convAllbottom() {
                this.val2 = this.balances.find(b => b.title === this.crypto2.short_title).value;
                this.calc(2);
            }
        },
        created() {
            this.findCourse('USDT2BTC');
        }
    }
</script>
<style scoped>
    .convAllBtn {
        font-size: 14px;
        color: #35634F;
        position: absolute;
        top: -28px;
        right: 5px;
        cursor: pointer;
    }
</style>