<template>
    <section>
    <div v-if="invoice.status == 'Отмена' && !localStorageGetItem(selectedPaymentMethod)" class="container" style="min-height: 70vh;">
        <div class="row mt-4 mb-3">
            <div class="col d-flex align-items-end">
                <h3 class="mb-0">Счет №{{invoice.user_id}}-{{invoice.id}} был отменен получателем</h3>
            </div>
        </div>
    </div>
    <div v-else-if="invoice.status == 'Успех' && !localStorageGetItem(selectedPaymentMethod)" class="container" style="min-height: 70vh;">
        <div class="row mt-4 mb-3">
            <div class="col d-flex align-items-end">
                <h3 class="mb-0">Счет №{{invoice.user_id}}-{{invoice.id}} был оплачен</h3>
            </div>
        </div>
    </div>
    <div v-else-if="invoice.status == 'Подтвержден' && !localStorageGetItem(selectedPaymentMethod)" class="container" style="min-height: 70vh;">
        <div class="row mt-4 mb-3">
            <div class="col d-flex align-items-end">
                <h3 class="mb-0">Счет №{{invoice.user_id}}-{{invoice.id}} был подтверждён</h3>
            </div>
        </div>
    </div>
    <div v-else-if="invoice.status == 'В процессе' && !localStorageGetItem(selectedPaymentMethod)" class="container" style="min-height: 70vh;">
        <div class="row mt-4 mb-3">
            <div class="col d-flex align-items-end">
                <h3 class="mb-0">Счет №{{invoice.user_id}}-{{invoice.id}} ожидает подтверждения оплаты</h3>
            </div>
        </div>
    </div>
    <div class="container" style="min-height: 70vh;">
        <second-part
            :step="step"
            :invoice="invoice"
            :crypto="crypto"
            :crypto_id="crypto_id"
            :selected_method="selectedPaymentMethod"
            :payment="paymentInfo"
            :country="getCountry"
            :minutes="minutes"
            :seconds="seconds"
            @change_method="changeMethod"
            @confirm_payment="confirmPayment"
            v-if="this.invoice.status == 'В процессе' 
            || this.invoice.status == 'Подтвержден'
            || (this.invoice.status == 'Активен' && (this.step == 2 || this.step == 3))
            /*|| (this.invoice.status == 'Активен' && this.lastPaymentStatus == 'Подтверждена')
            || (this.invoice.status == 'Активен' && this.lastPaymentStatus == 'В процессе')*/
            "
        ></second-part>
        <third-part
            :lastPaymentStatus="lastPaymentStatus"
            :invoice="invoice"
            :crypto="crypto"
            :crypto_id="crypto_id"
            :selected_method="selectedPaymentMethod"
            :payment="paymentInfo"
            :country="getCountry"
            :payment_status="paymentStatus"
            @change_method="changeMethod"
            @confirm_payment="confirmPayment"
            v-else-if="this.invoice.status == 'Успех' 
            || this.invoice.status == 'Отмена'
            || (this.invoice.status == 'Активен' && (this.step == 4))
            /*|| (this.invoice.status == 'Активен' && this.lastPaymentStatus == 'Успех')
            || (this.invoice.status == 'Активен' && this.lastPaymentStatus == 'Отмена')*/
            "
        ></third-part>
        <first-part
            :bank_list="bankList"
            :wallets="walletList"
            :invoice="invoice"
            :crypto="crypto"
            @select_method="selectPaymentMethod"
            v-else
    ></first-part>

    </div>
</section>
</template>

<script>
import FirstPart from "./InvoicePayment/FirstPart.vue";
import SecondPart from "./InvoicePayment/SecondPart.vue";
import ThirdPart from "./InvoicePayment/ThirdPart.vue";

export default {
    components: {ThirdPart, SecondPart, FirstPart},
    props: ['invoice'],
    data () {
        return {
            step: 1,
            bankList: {
                'ru' : [],
                'kz' : [],
                'uz' : [],
                'eu' : [],
            },
            minutes: 29,
            seconds: 59,
            selectedPaymentMethod: '',
            walletList: '',
            crypto_id: 0,
            country: '',
            paymentInfo: '',
            paymentStatus: '',
            currencies: '',
            crypto: '',
            lastPaymentStatus: '',
        }
    },
    watch: {
        'invoice.payments': {
            handler: function(newVal, oldVal) {
                if (Array.isArray(newVal) && newVal.length > 0) {
                    this.lastPaymentStatus = newVal[newVal.length - 1] ? newVal[newVal.length - 1].operation.status : '';
                } else {
                    this.lastPaymentStatus = '';
                }
            },
            immediate: true
        },
        step(newVal) {
            localStorage.setItem('step', newVal);
        },
        selectedPaymentMethod(newVal) {
            localStorage.setItem('selectedPaymentMethod', JSON.stringify(newVal));
        },
        paymentInfo(newVal) {
            localStorage.setItem('paymentInfo', JSON.stringify(newVal));
        }
    },
    computed: {
        getCountry() {
            let a = this.bankList['ru'].find(b => b.name == this.selectedPaymentMethod.name);
            if(a){
                return 'ru';
            }
            else {
                a = this.bankList['kz'].find(b => b.name == this.selectedPaymentMethod.name);
                if(a){
                    return 'kz';
                }
                else {
                    a = this.bankList['uz'].find(b => b.name == this.selectedPaymentMethod.name);
                    if(a){
                        return 'uz';
                    }
                    else {
                        return 'eu';
                    }
                }
            }
            
        },
    },
    created() {
        this.getBankList();
        this.getWallets();
        this.getCurrencies();
        this.getCryptos();
        eventBus.$emit('turnOnPusher', true);
        if(invoice.type !== 'Многоразовый') {
            if(localStorage.getItem('step')) {
                this.step = parseInt(localStorage.getItem('step'));
            }
            if(localStorage.getItem('selectedPaymentMethod')) {
                this.selectedPaymentMethod = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
            }
            if(localStorage.getItem('paymentInfo')) {
                this.paymentInfo = JSON.parse(localStorage.getItem('paymentInfo'));
            }
        }
        this.countDownTimer();

    },
    methods: {
        getBankList() {
            axios
                .get(this.$api_address + '/banks?country=ru')
                .then(response => (this.bankList['ru'] = response.data));
            axios
                .get(this.$api_address + '/banks?country=kz')
                .then(response => (this.bankList['kz'] = response.data));
            axios
                .get(this.$api_address + '/banks?country=uz')
                .then(response => (this.bankList['uz'] = response.data));
            axios
                .get(this.$api_address + '/banks?country=eu')
                .then(response => (this.bankList['eu'] = response.data));    
        },
        countDownTimer() {
            if(this.seconds > 0) {
                setTimeout(() => {
                    this.seconds -= 1
                    this.countDownTimer()
                }, 1500)
            }
            if(this.seconds == 0 && this.minutes != 0) {
                this.seconds = 59;
                this.minutes -= 1;
                this.countDownTimer();
            }
            if(this.seconds == 0 && this.minutes == 0) {
                this.changeMethod();
                alert('Закончилось время для совершения платежа');
                // axios.post('/payment/cancel', {payment: this.paymentInfo.id, crypto_id: this.crypto_id, currency: this.country}).then(response =>
                // {
                //     alert('Закончилось время для соврежшения платежа');
                // });
            }
        },
       
        selectPaymentMethod(select) {
            console.log('select', select);
            this.selectedPaymentMethod = select.selectedPaymentMethod;

            const startTime = Date.now();
            localStorage.setItem('timerStart', startTime.toString());

            // if(this.paymentInfo != '') {
            //     paymentID = this.paymentInfo.id
            // }

            //Если это крипта
            if(this.selectedPaymentMethod.crypto_id) {
                console.log('cr');
                let data = {
                    wallet_id: this.selectedPaymentMethod.id,
                    amount: this.invoice.commission_type == 'Плательщик' ? parseFloat(this.invoice.amount) + parseFloat(this.invoice.commission) : this.invoice.amount,
                    cryptocurrency_id: this.selectedPaymentMethod.crypto_id,
                    check_id: this.invoice.id,
                    token: this.invoice.token,
                };

                axios.post(this.$api_address + "/deposits", data, {
                    validateStatus: function (status) {
                        return status < 500;
                    }
                }).then(response => {
                    if(response.data.message) {
                        alert(response.data.message);
                    };
                    this.paymentInfo = response.data.data;
                    if(this.paymentInfo.status) {
                        this.lastPaymentStatus = this.paymentInfo.status
                    }
                    this.countDownTimer();
                    this.checkStatus();
                    this.step = 2;
                }).catch(error => {
                    console.log(error);
                });
            }
            //Если это карта
            else {
                let data = {
                    bank_id: this.selectedPaymentMethod.id,
                    amount: this.invoice.commission_type == 'Плательщик' ? parseFloat(this.invoice.amount) + parseFloat(this.invoice.commission) : this.invoice.amount,
                    cryptocurrency_id: 1,
                    currency_id: this.currencies.find(c => c.country_key.toLowerCase() === this.getCountry.toLowerCase()).id,
                    check_id: this.invoice.id,
                    token: this.invoice.token,
                };

                axios.post(this.$api_address + "/transactions", data, {
                    validateStatus: function (status) {
                        return status < 500;
                    }
                }).then(response => {
                    if(response.data.message) {
                        alert(response.data.message);
                    };
                    this.paymentInfo = response.data.data;
                    if(this.paymentInfo.status) {
                        this.lastPaymentStatus = this.paymentInfo.status
                    }
                    this.countDownTimer();
                    this.checkStatus();
                    this.step = 2;
                }).catch(error => {
                    console.log(error);
                });
            }

        },
        changeMethod(){
            if(this.selectedPaymentMethod.crypto_id) {
                axios.delete(this.$api_address + '/deposits/' + this.paymentInfo.id, {
                    params: {
                        token: this.invoice.token,
                        isChangePayment: 1
                    }
                })
            } else {
                axios.delete(this.$api_address + '/transactions/' + this.paymentInfo.id, {
                    params: {
                        token: this.invoice.token,
                        isChangePayment: 1
                    }
                })
            }
            this.selectedPaymentMethod = '';
            this.step = 1;
            this.minutes = 29;
            this.seconds = 59;
        },
        confirmPayment(){
            this.step = 3;
            //this.updateStatus();
        },

        checkStatus() {
            let invoicePayment = this.invoice.payments.find(p => p.operation.id == this.paymentInfo.id);
            if(invoicePayment) {
                if(invoicePayment.operation.status == 'Успех' || invoicePayment.operation.status == 'Отмена') {
                    this.step = 4;
                } else {
                    setTimeout(() => {
                        this.checkStatus()
                    }, 1000)
                }
            } else {
                setTimeout(() => {
                        this.checkStatus()
                    }, 1000)
            }
        },

        //Получаем кошельки
        getWallets(){
            axios.get(this.$api_address + '/deposit_wallets').then(response =>
            {
                this.walletList = response.data.data;
            });
        },

        //Получаем валюты
        getCurrencies() {
        return axios
            .get(this.$api_address + "/currencies")
            .then((response) => {
            this.currencies = response.data.data;
            })
            .catch((error) => {
            console.error("Error fetching crypto:", error);
            });
        },

         //Получаем крипту
        getCryptos() {
        return axios
            .get(this.$api_address + "/cryptocurrencies")
            .then((response) => {
            this.crypto = response.data.data;
            })
            .catch((error) => {
            console.error("Error fetching crypto:", error);
            })
        },

        localStorageGetItem(item) {
            return localStorage.getItem('selectedPaymentMethod');
        }


    }
}
</script>
