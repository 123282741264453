<template>
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' id='myCard' aria-hidden='true' tabindex='-1' >
            <div class='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div class='modal-content no-transition'>
                    <div class='modal-header'>
                        <h4 class='modal-title'>Мои карты</h4>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' aria-label='Close'></button>
                    </div>
                    <div class="modal-body">
                        <div class='cardBlock'>
                            <div class='cardList'>
                                <card :key='data.id' v-for='data in enabledCards' :data='data' ></card>
                                <h5 v-if="disabledCards.length > 0" class="mt-4 mb-2">Недоступные карты</h5>
                                <card v-if="disabledCards.length > 0" :key='data.id' v-for='data in disabledCards' :data='data' ></card>
                            </div>
                        </div>
                    </div>
                    <button class='lk__cart-right w-100' data-bs-dismiss="modal" @click='showAddCard'>
                        <div class='lk__cart-add'>+</div>
                        <span id='addCardButton' class='u-font-size-16'>Добавить карту</span>
                    </button>
                </div>
            </div>
            <add-card v-show='forModal.isAddCardVisible' @close='closeAddCard' />
        </div>
    </transition>
</template>

<script>
import Card from './Card.vue';
import AddCard from './AddCard.vue';
import CardsComponent from "./CardsComponent.vue";
export default {
    props: ['cards', 'bank_list'],
    components: {
        Card,
        AddCard,
    },
    watch: {
        cards: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal && this.bank_list) {
                    this.processCards();
                }
            }
        },
        bank_list: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal && this.cards) {
                    this.processCards();
                }
            }
        }
    },
    data() {
        return {
            forCards: {
                string: '123',
                new_card: {
                    title: '',
                    number: '',
                },
            },
            forModal: {
                isAddCardVisible: false
            },
            enabledCards: [],
            disabledCards: [],
            banklist: [],
        }
    },
    methods: {
        showAddCard() {
            console.log('Добавить карту');
            this.$emit('showAddCard');
            // this.forModal.isAddCardVisible = true;
        },
        closeAddCard() {
            this.forModal.isAddCardVisible = false;
        },
        close(event) {
            this.$emit('close');
        },
        listenChange(data) {
            this.forCards.cards = data;
            this.forCards.cardsCount = this.forCards.cards.length;
        },
        processCards() {
            let cardsD = [];
            let cardsE = [];
            
            this.cards.forEach((card) => {
                let bankk = this.bank_list[card.country].find(bank => bank.id === card.bank_id);
                if (bankk) {
                    let netCard = {
                        ...bankk,
                        ...card
                    }
                    cardsE.push(netCard);
                } else {
                    let noTitleBank = {
                        'name': 'no_title'
                    }
                    let netCard = {
                        ...card,
                        ...noTitleBank
                    }
                    cardsD.push(netCard);
                }
            });
        
            this.enabledCards = cardsE;
            this.disabledCards = cardsD;
        },
    },
}
</script>


