<template>
    <transition name='modal-fade'>
    <div class="modal fade new_custom_modal" id="saleCryptoModal" aria-hidden="true" style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header d-flex align-items-center justify-content-between flex-wrap">
                            <h5 class="modal-title order-1">Продажа {{crypto.short_title}}</h5>

                            <div class="mx-0 mx-md-auto order-3 order-md-2 col-12 col-md-4 px-0 py-3 py-md-0 d-flex align-items-center">
                                <span :class="step > 0 ? 'operation_steps_active' : 'operation_steps'">1</span>
                                <img width="49" height="2" src="/static/img/buy/line.svg" />
                                <span :class="step > 1 ? 'operation_steps_active' : 'operation_steps'">2</span>
                                <img width="49" height="2" src="/static/img/buy/line.svg" />
                                <span :class="step > 2 ? 'operation_steps_active' : 'operation_steps'">3</span>
                            </div>

                            <button v-if="step == 2" class='d-flex flex-row align-items-center flex-nowrap text-white order-2 order-md-3' type='button' @click='prev'>
                                <img src="/img/ico/white-arrow.svg" class="me-1"> 
                                <span>Назад</span>
                            </button>
                            <button v-else class="btn-close btn-close-white shadow-none order-2 order-md-3" type="button" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>

                <div class="error-block-text" v-if="error"><p>{{error}}</p></div>

                <!-- Шаг 1 -->
                <div class="modal-body" v-if="step == 1">
                    <div class="custom-modal__text mb-3 text-start">Куда вывести средства?</div>
                    <card-for-pop-up :cards="cards" :title="title" @new_card="newCard" @put="putCardNumber" :bank_list="bank_list"></card-for-pop-up>
                    <span v-if="cards.length == 0" class="mb-2">Добавьте карты, чтобы купить криптовалюту</span>
                    <button :disabled='this.form.cardNumber ? false : true' class='button button--full-width mb-3' @click='firstStep()'>Выбрать</button>
                </div>

                <!-- Шаг 2 -->
                <div class="modal-body" v-if="step == 2">
                    <div class="custom-modal__tab o-tab my-2">Текущий курс: 1 {{crypto.short_title}} = {{currencyCourse}} {{currency}}</div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4 align-items-center">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Сколько продать {{crypto.short_title}}?</div>
                        <div class="custom-modal__wrapper-right">
                            <input class="o-input" style="text-align: center" v-model="form.amount" @keyup='upd("rub")' value="0" type="number" />
                            <div @click="saleAll" v-if="this.form.amount != this.balance" class="position-relative">
                                <span style="font-size: 14px; color: #35634F; position: absolute; top: -34px; right: 5px; cursor: pointer;">Продать все</span>
                            </div>
                            <div class="error-block-text" v-show="errorInput != ''"><p>{{errorInput}}</p></div>
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4 align-items-center">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Вы получите {{currency}}</div>
                        <div class="custom-modal__wrapper-right">
                            <input class="o-input" style="text-align: center" v-model="form.amountCurrency" @keyup='upd("crypto")' value="0" type="number" />
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start u-font-weight-600">Текущий баланс:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14"><span>{{new Intl.NumberFormat('ru-RU').format(parseFloat(balance))}} {{crypto.short_title}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Остаток баланса:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600"><span>{{new Intl.NumberFormat('ru-RU').format(parseFloat(form.ost))}} {{crypto.short_title}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start"></div>
                        <!-- Если верифицирован -->
                        <div v-if="user.status.id == 3" class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <button :disabled="query && (errorInput != '' || form.amount == 0) ? true: false" @click="secondStep" class="button button--full-width mb-3" >Продать</button>
                        </div>
                        <!-- Если не верифицирован -->
                        <div v-else class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <span style="color: rgb(172, 175, 255);">
                                Пройдите <span @click="showVerification" class='text-light cursor-pointer'>верификацию</span> для совершения продажи
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Шаг 3 -->
                <div class='modal-body' v-if="step == 3">
                    <div style='text-align: left; margin-bottom: 20px'>
                        <span class='me-3' style='font-size: 16px'>Ваша заявка на продажу {{this.form.amount}} {{crypto.short_title}} отправлена</span>
                    </div>
                    <button class='button button--full-width mb-3' @click='lastStep()'>Новая продажа</button>
                </div>

            </div>
        </div>
    </div>
</transition>
</template>

<script>
import CardForPopUp from "../Cards/CardForPopUp.vue";

export default {
    components: {CardForPopUp},
    props: ['balance', 'cards', 'courses', 'crypto', 'user', 'courses_list', "bank_list", 'currencies'],
    data() {
        return {
            form : {
                bank: '',
                bankName: '',
                amount: null,
                amountCurrency: null,
                file: '',
                cardNumber: '',
                ost: this.balance,
                giv: 0,
            },
            step: 1,
            title: 'sale' + this.crypto.short_title,
            error: '',
            country: '',
            errorInput: '',
            query: false,
        }
    },
    computed: {
        currency : function () {
            if(this.country == 'kz') {
                return 'KZT'
            } else {
                if(this.country == 'uz') {
                    return 'UZS'
                } else if (this.country == 'ru') {
                    return 'RUB'
                } else {
                    return "EUR";
                }
            }
        },

        currencyCourse() {
            if (this.courses_list && this.courses_list['Продажа']) {
                let course = this.courses_list['Продажа'].find(c => c.cryptocurrency_id_from === this.crypto.id 
                && c.currency_id_to === this.currencies.find(c => c.short_title === this.currency).id).rate;
                return parseFloat(course).toFixed(2);
            } else {
                return 1;
            }
        },

        courses_USDT2rub_rate() {
            console.log('courses_list', this.courses);
            if (this.courses_list && this.courses_list['Продажа']) {
                const btc2usdt = Object.values(this.courses_list['Продажа']).find(course => course.id == 2);
                return btc2usdt ? btc2usdt.rate : 0;
            }
            return 0;
        },
        courses_USDT2kzt_rate() {
            if (this.courses_list && this.courses_list['Продажа']) {
                const usdt2kzt = Object.values(this.courses_list['Продажа']).find(course => course.id == 4);
                return usdt2kzt ? usdt2kzt.rate : 0;
            }
            return 0;
        },
        courses_USDT2uzs_rate() {
            if (this.courses_list && this.courses_list['Продажа']) {
                const usdt2uzs = Object.values(this.courses_list['Продажа']).find(course => course.id == 3);
                return usdt2uzs ? usdt2uzs.rate : 0;
            }
            return 0;
        },
        courses_USDT2eur_rate() {
            if (this.courses_list && this.courses_list['Продажа']) {
                const usdt2eur = Object.values(this.courses_list['Продажа']).find(course => course.id == 55);
                return usdt2eur ? usdt2eur.rate : 0;
            }
            return 0;
        },

        currency_course: function () {
            let crs = 1
            switch (this.currency) {
                case 'UZS':
                    crs = this.courses_USDT2uzs_rate;
                    break;
                case 'RUB':
                    crs = this.courses_USDT2rub_rate;
                    break;
                case 'KZT':
                    crs = this.courses_USDT2kzt_rate;
                    break;
                case "EUR":
                    crs = this.courses_USDT2eur_rate;
                    break;
            }
            return crs;
        },
        // courseNow() {
        //     if(this.currency_course && this.courses) {
        //         return parseFloat(this.courses * this.currency_course).toFixed(2);
        //     } else {
        //         return 1;
        //     }
        // },
    },
    methods: {
        showVerification() {
                this.$emit('showVerification');
            },
        saleAll() {
            this.form.amount = this.balance;
            this.upd('rub');
        },
        prev() {
          this.step--;
          this.form.amount = '';
          this.upd('rub');
        },
        close(event) {
            this.$emit('close');
        },
        sale(data) {
            this.$emit('sale', data);
        },
        upd(type) {
            setTimeout(() => {
                if(type == 'rub'){
                    this.form.amountCurrency = Math.ceil(parseFloat(this.form.amount) * parseFloat(this.currencyCourse));
                } else {
                    this.form.amount = (parseFloat(this.form.amountCurrency) / parseFloat(this.currencyCourse)).toFixed(this.crypto.count_after_point);
                    this.form.amountCurrency = Math.ceil(this.form.amountCurrency);
                }
                this.form.ost = this.balance - this.form.amount;

                if(this.user.status.id == 3) {
                    if(parseFloat(this.form.amount) > 100000 && this.crypto.id == 1) {
                        this.errorInput = 'Максимальная сумма продажи 100000 USDT';
                        return
                    }
                    else {
                        this.errorInput = '';
                    }
                    if(parseFloat(this.form.amount) > 0.3 && this.crypto.id == 2) {
                        this.errorInput = 'Максимальная сумма продажи 0.3 BTC';
                        return
                    } else {
                        this.errorInput = '';
                    }
                    if(parseFloat(this.form.amount) < 10 && this.crypto.id == 1) {
                        this.errorInput = 'Минимальная сумма продажи 10 USDT';
                        return
                    } else {
                        this.errorInput = '';
                    }
                    if(parseFloat(this.form.amount) < 0.0005 && this.crypto.id == 2) {
                        this.errorInput = 'Минимальная сумма продажи 0.0005 BTC'
                        return
                    } else {
                        this.errorInput = '';
                    }
                } 
                this.form.giv = parseInt(this.form.amount * this.courses)
            }, 2500);
        },
        firstStep() {
            this.step = 2;
        },

        // Второй шаг
        secondStep() {
            if (this.errorInput != '' || this.form.amount == 0) {
                return
            }
            this.query = true;
            let data = {
                bank_name: this.form.bankName,
                card_number: this.form.cardNumber,
                amount: this.form.amount,
                // course:this.courses,
                // bank: this.form.bank,
                // file: this.form.file,
                // country:this.country
                // crypto: this.crypto,
                currency_id: this.currencies.find(c => c.short_title === this.currency).id,
                cryptocurrency_id: this.crypto.id,
                user_id: this.$user_ID.value,
                token: sessionStorage.getItem('user_token')
            }
            console.log('saleData', data);
            axios.post(this.$api_address + '/payouts', data).then(response => {
                if((response.data.error)) {
                    this.error = response.data.error;
                    this.query = false;
                } else {
                    this.sale(response.data);
                    this.step = 3;
                    this.query = false;
                }
            });
        },
        lastStep() {
            this.step = 1;
            this.form.amount = null;
            this.form.amountCurrency = null;
            this.form.file = '';
            this.form.cardNumber = '';
            this.error = '';
            this.bank = 0;
            this.form.bank = '';
            this.errorInput = '';
            this.query = false;
            this.form.giv = 0;
        },
        newCard(){
            this.$emit('new_card');
        },
        putCardNumber(data) {
            console.log('putCardNumber', data);
            this.form.cardNumber = data.number;
            this.form.bank = data.bank_id;
            this.form.bankName = data.bank_name;
            this.country = data.country;
        }

    },
}
</script>
