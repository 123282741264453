<template>
    <!-- Покупка -->
    <div v-if="data.operation.operation_title  == 'Покупка'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/ico/ico-history-buy.svg" width="14" height="12">
            </div>
            <div class='history-item-action'>
                {{data.operation.operation_title}}
            </div>
            <div class='history-item-desc u-color-white'>
                <span>
                    {{parseFloat(data.operation.cryptocurrency_amount).toFixed(crypto.find(c => c.id == data.operation.crypto_id).count_after_point) + " " + (crypto.find(c => c.id == data.operation.crypto_id).short_title)}}
                </span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'>
                <span v-if="data.operation.status != 'Успех'">{{data.operation.status}}</span>
                <span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
            <div  class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg' />
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'">Время создания заявки:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс до:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс после:</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">Операция отменена:</span>
                <span>Карта покупки:</span>
                <span v-if="data.operation.status != 'Отмена'">Сумма покупки:</span>
                <button v-if="data.operation.status == 'В процессе'" class="btn btn-outline-danger p-1" @click="cancelBuy(data.operation.id)">Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.outer_id}}</span>
                <!-- Время создания заявки -->
                <span v-if="data.operation.status == 'В процессе'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm:s") }}</span>
                <!-- Баланс до: -->
                <span v-if="data.operation.status == 'Успех'">{{parseFloat(data.operation.balance_before).toFixed(crypto.find(c => c.id === data.operation.crypto_id).count_after_point)}}</span>
                <!-- Баланс после: -->
                <span v-if="data.operation.status == 'Успех'">{{parseFloat(data.operation.balance_after).toFixed(crypto.find(c => c.id === data.operation.crypto_id).count_after_point)}}</span>
                <!-- Операция отменена: -->
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">{{moment(data.operation.updated_at).format("DD-MM-YYYY HH:mm")}}</span>
                <!-- Карта покупки: -->
                <span>{{data.operation.card_number | format}}</span>
                <!-- Сумма покупки: -->
                <span v-if="data.operation.status != 'Отмена'">{{new Intl.NumberFormat('ru-RU').format(parseInt(data.operation.currency_amount))}} {{currencies.find(c => c.id == data.operation.currency_id).short_title}}</span>
            </div>
        </div>
    </div>

    <!-- Продажа -->
    <div v-else-if="data.operation.operation_title  == 'Продажа'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/ico/ico-history-sale.svg" width="14" height="12">
            </div>
            <div class='history-item-action'>{{data.operation.operation_title}}</div>
            <div class='history-item-desc u-color-white'>
                <span>
                    {{parseFloat(data.operation.cryptocurrency_amount).toFixed(crypto.find(c => c.id == data.operation.crypto_id).count_after_point) + " " + (crypto.find(c => c.id == data.operation.crypto_id).short_title)}}
                </span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'>
                <span v-if="data.operation.status != 'Успех'">{{data.operation.status}}</span>
                <span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
            <div class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg' />
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'">Время создания заявки:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс до:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс после:</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе' && data.operation.status != 'В ожидании'">Операция отменена:</span>
                <span>Карта продажи:</span>
                <span v-if="data.operation.status != 'Отмена'">Сумма продажи:</span>
                <span v-if="data.operation.status == 'Отмена'">Причина отмены</span>
                <button v-if="data.operation.status == 'В ожидании'" class="btn btn-outline-danger p-1" @click="cancelSale(data.operation.id)" >Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.outer_id}}</span>
                <!-- Время создания заявки: -->
                <span v-if="data.operation.status == 'В процессе'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm") }}</span>
                <!-- Баланс до: -->
                <span v-if="data.operation.status == 'Успех'">
                    {{parseFloat(data.operation.balance_before).toFixed(crypto.find(c => c.id === data.operation.crypto_id).count_after_point)}}
                </span>
                <!-- Баланс после: -->
                <span v-if="data.operation.status == 'Успех'">
                    {{parseFloat(data.operation.balance_after).toFixed(crypto.find(c => c.id === data.operation.crypto_id).count_after_point)}}
                </span>
                <!-- Операция отменена: -->
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе' && data.operation.status != 'В ожидании'">{{moment(data.operation.updated_at).format("DD-MM-YYYY HH:mm")}}</span>
                <!-- Карта продажи: -->
                <span>{{data.operation.card_number | format}}</span>
                <!-- Сумма продажи: -->
                <span v-if="data.operation.status != 'Отмена'">{{new Intl.NumberFormat('ru-RU').format(parseInt(data.operation.currency_amount))}} {{currencies.find(c => c.id == data.operation.currency_id).short_title}}</span>
                <!-- Причина отмены -->
                <span v-if="data.operation.status == 'Отмена'">{{data.operation.cancellation_message}}</span>
            </div>
        </div>
    </div>

    <!-- Конвертация -->
    <div v-else-if="data.operation.operation_title  == 'Конвертация'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/ico/ico-history-convert.svg" width="16" height="12">
            </div>
            <div class='history-item-action u-color-white'>{{data.operation.operation_title}}</div>
            <div class='history-item-desc u-color-white'>
                <span>{{crypto.find(c => c.id === data.operation.crypto_id_from).short_title}}
                    <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8">
                    {{crypto.find(c => c.id === data.operation.crypto_id_to).short_title}}
                </span>
            </div>
            <div class='history-item-date u-color-green'><span>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span></div>
            <div class='history-item-toggle-ico' @click="toggle"><img src='/static/img/ico/ico-accordeon.svg' /></div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span>Баланс {{crypto.find(c => c.id === data.operation.crypto_id_to).short_title}} до</span>
                <span>Баланс {{crypto.find(c => c.id === data.operation.crypto_id_to).short_title}} после</span>
                <span>Баланс {{crypto.find(c => c.id === data.operation.crypto_id_from).short_title}} до</span>
                <span>Баланс {{crypto.find(c => c.id === data.operation.crypto_id_from).short_title}} после</span>
                <button v-if="data.operation.status == 'В ожидании'" class="btn btn-outline-danger p-1">Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.id}}</span>
                <!-- Баланс до -->
                <span>{{parseFloat(data.operation.balance_before_to).toFixed(crypto.find(c => c.id === data.operation.crypto_id_to).count_after_point)}}</span>
                <!-- Баланс после -->
                <span>{{parseFloat(data.operation.balance_after_to).toFixed(crypto.find(c => c.id === data.operation.crypto_id_to).count_after_point)}}</span>
                <!-- Баланс до -->
                <span>{{parseFloat(data.operation.balance_before_from).toFixed(crypto.find(c => c.id === data.operation.crypto_id_from).count_after_point)}}</span>
                <!-- Баланс после -->
                <span>{{parseFloat(data.operation.balance_after_from).toFixed(crypto.find(c => c.id === data.operation.crypto_id_from).count_after_point)}}</span>
            </div>
        </div>
    </div>

    <!-- Вывод -->
    <div v-else-if="data.operation.operation_title  == 'Вывод'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img v-if="data.operation.status == 'Успех'" src="/static/img/ico/ico-history-withdrawal-success.svg" width="16" height="13">
                <img v-else-if="data.operation.status == 'В процессе'" src="/static/img/ico/ico-history-withdrawal-process.svg" width="15" height="12">
                <img v-else src="/static/img/ico/ico-history-withdrawal-other.svg" width="16" height="13">
            </div>
            <div class='history-item-action'>
                {{data.operation.operation_title}}
            </div>
            <div class='history-item-desc u-color-white'>
                <span>
                    {{parseFloat(data.operation.cryptocurrency_amount).toFixed(crypto.find(c => c.id == data.operation.crypto_id).count_after_point) + " " + (crypto.find(c => c.id == data.operation.crypto_id).short_title)}}
                </span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'>
                <span v-if="data.operation.status != 'Успех'">{{data.operation.status}}</span>
                <span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
            <div :class="data.operation.status == 'В процессе' ? 'u-color-green' : data.operation.status == 'Успех'  ? 'u-color-white' : 'u-color-red'" class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg' />
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'">Время создания заявки:</span>
                <span v-if="data.operation.status != 'Отмена'">Баланс до:</span>
                <span v-if="data.operation.status != 'Отмена'">Баланс после:</span>
                <span v-if="data.operation.status != 'Отмена'">Комиссия:</span>
                <span v-if="data.operation.status == 'Отмена'">Операция отменена:</span>
                <span>Кошелек:</span>
                <span v-if="data.operation.status != 'Отмена'">К зачислению:</span>
                <span v-if="data.operation.status == 'Отмена'">Причина отмены</span>
                <button v-if="data.operation.status == 'В ожидании'" class="btn btn-outline-danger p-1" @click="cancelConlusion(data.operation.id)">Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.outer_id}}</span>
                <!-- Время создания заявки: -->
                <span v-if="data.operation.status == 'В процессе'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm:s") }}</span>
                <!-- Баланс до: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{parseFloat(data.operation.balance_before).toFixed(crypto.find(c => c.id == data.operation.crypto_id).count_after_point)}}
                </span>
                <!-- Баланс после: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{parseFloat(data.operation.balance_after).toFixed(crypto.find(c => c.id == data.operation.crypto_id).count_after_point)}}
                </span>
                <!-- Комиссия: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{data.operation.commission}} {{(crypto.find(c => c.id == data.operation.crypto_id).short_title)}}
                </span>
                <!-- Операция отменена: -->
                <span v-if="data.operation.status == 'Отмена'">{{moment(data.operation.updated_at).format("DD-MM-YYYY H:mm")}}</span>
                <!-- Кошелек: -->
                <span>{{data.operation.wallet.wallet_hash}}</span>
                <!-- К зачислению: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{parseFloat(data.operation.cryptocurrency_amount).toFixed(crypto.find(c => c.id === data.operation.crypto_id).count_after_point)}} 
                    {{crypto.find(c => c.id === data.operation.crypto_id).short_title}}
                </span>
                <!-- Причина отмены -->
                <span v-if="data.operation.status == 'Отмена'">{{data.operation.cancellation_message}}</span>
            </div>
        </div>
    </div>

    <!-- Депозит -->
    <div v-else-if="data.operation.operation_title  == 'Депозит'" class='history-item' v-bind:class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/ico/ico-history-deposit.svg" alt="депозит" width="16" height="13">
            </div>
            <div class='history-item-action'>{{data.operation.operation_title}}</div>
            <div class='history-item-desc u-color-white'>
                <span v-if="data.operation.actual_amount">{{data.operation.actual_amount + " " + (crypto.find(c => c.id === data.operation.crypto_id).short_title)}}</span><span v-else>{{data.operation.proposed_amount + " " + (crypto.find(c => c.id === data.operation.crypto_id).short_title)}}</span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'><span v-if="data.operation.status != 'Успех'">{{data.operation.status}}</span><span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span></div>
            <div class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg' />
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'||data.operation.status == 'Подтвержден'">Время создания заявки:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс до:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс после:</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">Операция отменена:</span>
                <button v-if="data.operation.status == 'В процессе'" @click="cancelDeposit(data.operation.id)" class="btn btn-outline-danger p-1">Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <span>{{data.operation.outer_id}}</span>
                <span v-if="data.operation.status == 'В процессе'||data.operation.status == 'Подтвержден'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm:s") }}</span>
                <span v-if="data.operation.status == 'Успех'">{{parseFloat(data.operation.balance_before).toFixed(crypto.find(c => c.id === data.operation.crypto_id).count_after_point)}}</span>
                <span v-if="data.operation.status == 'Успех'">{{parseFloat(data.operation.balance_after).toFixed(crypto.find(c => c.id === data.operation.crypto_id).count_after_point)}}</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
        </div>
    </div>

    <!-- Наличные -->
    <div v-else-if="data.operation.operation_title  == 'Наличные'" class='history-item' v-bind:class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/ico/ico-history-cashe.svg" width="16" height="13">
            </div>
            <div class='history-item-action'>{{data.operation.operation_title}}</div>
            <div class='history-item-desc u-color-white'>
                <span>{{data.operation.operation_type + " " + data.operation.cryptocurrency_amount + " " + "USDT"}}</span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'>
                <span v-if="data.operation.status != 'Успех'">{{data.operation.status}}</span>
                <span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY HH:mm") }}</span>
            </div>
            <div class='history-item-toggle-ico' @click="toggle"><img src='/static/img/ico/ico-accordeon.svg' /></div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span>Время размещения заявки:</span>
                <span v-if="data.operation.status == 'Успех'">Время завершения заявки:</span>
                <span v-if="data.operation.status == 'Отмена'">Время отмены заявки:</span>
                <span v-if="data.operation.status == 'Отмена'">Причина отмены:</span>
                <span v-if="data.operation.operation_type == 'Покупка'">Сумма покупки</span>
                <span v-if="data.operation.operation_type == 'Продажа'">Сумма продажи</span>
                <span v-if="(data.operation.status == 'В ожидании'|| data.operation.status == 'В процессе') && data.operation.operation_type == 'Покупка'">Необходимо заплатить</span>
                <span v-if="(data.operation.status == 'В ожидании'|| data.operation.status == 'В процессе') && data.operation.operation_type == 'Продажа'">Наличных к получению</span>
                <span v-if="data.operation.status == 'Успех' && data.operation.operation_type == 'Покупка'">Необходимо заплатить</span>
                <span v-if="data.operation.status == 'Успех' && data.operation.operation_type == 'Продажа'">Наличных получено</span>
                <span v-if="data.operation.status == 'В ожидании'|| data.operation.status == 'В процессе'">Комиссия</span>
                <span v-if="data.operation.status == 'В ожидании'|| data.operation.status == 'В процессе'">Город, офис</span>
                <span v-if="data.operation.status == 'В ожидании'|| data.operation.status == 'В процессе'">Дата и время посещения</span>
                <span v-if="data.operation.status == 'Успех' || data.operation.operation_type == 'Продажа'">Баланс до:</span>
                <span v-if="data.operation.status == 'Успех' || data.operation.operation_type == 'Продажа'">Баланс после:</span>
                <span v-if="(data.operation.status == 'В ожидании'|| data.operation.status == 'В процессе') && data.operation.operation_type == 'Продажа'">Код выдачи:</span>
                <span v-if="data.operation.status == 'В ожидании'">                
                    <button class="btn btn-outline-danger p-1" @click="cancelCashe(data.operation.id)">Отменить операцию</button>
                </span>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.outer_id}}</span>
                <!-- Время размещения заявки: -->
                <span>{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm:s") }}</span>
                <!-- Время завершения заявки: -->
                <span v-if="data.operation.status == 'Успех'">{{moment(data.operation.updated_at).format("DD-MM-YYYY HH:mm")}}</span>
                <!-- Время отмены заявки: -->
                <span v-if="data.operation.status == 'Отмена'">{{moment(data.operation.updated_at).format("DD-MM-YYYY HH:mm")}}</span>
                <!-- Причина отмены: -->
                <span v-if="data.operation.status == 'Отмена'">{{data.operation.cancellation_message}}</span>
                <!-- Сумма покупки -->
                <span v-if="data.operation.operation_type == 'Покупка'">{{parseFloat(data.operation.cryptocurrency_amount).toFixed(crypto[0] ? crypto[0].count_after_point : 0)}} USDT</span>
                <!-- Сумма продажи -->
                <span v-if="data.operation.operation_type == 'Продажа'">{{parseFloat(data.operation.cryptocurrency_amount).toFixed(crypto[0] ? crypto[0].count_after_point : 0)}} USDT</span>
                <!-- Необходимо заплатить -->
                <span v-if="(data.operation.status == 'В ожидании' || data.operation.status == 'В процессе') && data.operation.operation_type == 'Покупка'">{{data.operation.currency_amount}} {{currencies.find(c => c.id == data.operation.currency_id).short_title}}</span>
                <!-- Наличных к получению -->
                <span v-if="(data.operation.status == 'В ожидании' || data.operation.status == 'В процессе') && data.operation.operation_type == 'Продажа'">{{data.operation.currency_amount}}  {{currencies.find(c => c.id == data.operation.currency_id).short_title}} </span>
                <!-- Необходимо заплатить -->
                <span v-if="data.operation.status == 'Успех' && data.operation.operation_type == 'Покупка'">{{data.operation.currency_amount}} {{data.operation.currency}}</span>
                <!-- Наличных получено -->
                <span v-if="data.operation.status == 'Успех' && data.operation.operation_type == 'Продажа'">{{data.operation.currency_amount}} {{data.operation.currency}}</span>
                <!-- Комиссия -->
                <span v-if="data.operation.status == 'В ожидании' || data.operation.status == 'В процессе'">{{data.operation.commission}} {{currencies.find(c => c.id == data.operation.currency_id).short_title}}</span>
                <!-- Город, офис -->
                <span v-if="data.operation.status == 'В ожидании' || data.operation.status == 'В процессе'">{{data.operation.city}}, {{data.operation.address}}</span>
                <!-- Дата и время посещения -->
                <span v-if="data.operation.status == 'В ожидании' || data.operation.status == 'В процессе'">{{data.operation.date}} {{data.operation.time}}</span>
                <!-- Баланс до: -->
                <span v-if="data.operation.status == 'Успех' || data.operation.operation_type == 'Продажа'"> {{data.operation.balance_before}} USDT</span>
                <!-- Баланс после: -->
                <span v-if="data.operation.status == 'Успех' || data.operation.operation_type == 'Продажа'"> {{data.operation.balance_after}} USDT</span>
                <!-- Код выдачи: -->
                <span v-if="(data.operation.status == 'В ожидании' || data.operation.status == 'В процессе') && data.operation.operation_type == 'Продажа'">{{data.operation.code}}</span>
            </div>
        </div>
    </div>
</template>


<script>
import moment from "moment";
export default {
    props: ['data', 'crypto', 'currencies'],
    data () {
        return {
            isOpen: false,
            moment: moment,
        }
    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, '$1 '),
    },
    methods: {
        toggle() {
            if(this.isOpen === true) {
                this.isOpen = false;
            } else {
                this.isOpen = true;
            }
        },
        //Отмены операций
        cancelCashe(id) {
            axios.delete(this.$api_address + '/cashes/' + id, {
            params: {
                token: sessionStorage.getItem('user_token'),
                user_id: sessionStorage.getItem('user_ID'),
            },
            }).then(response => {
                alert('Операция успешно отменена')
            }).catch(function (error) {
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });
        },
        cancelSale(id) {
            axios.delete(this.$api_address + '/payouts/' + id, {
            params: {
                token: sessionStorage.getItem('user_token'),
                user_id: sessionStorage.getItem('user_ID'),
            },
            }).then(response => {
                alert('Операция успешно отменена')
            }).catch(function (error) {
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });
        },
        cancelBuy(id) {
            axios.delete(this.$api_address + '/transactions/' + id, {
            params: {
                token: sessionStorage.getItem('user_token'),
                user_id: sessionStorage.getItem('user_ID'),
            },
            }).then(response => {
                alert('Операция успешно отменена')
            }).catch(function (error) {
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });
        },
        cancelConlusion(id) {
            axios.delete(this.$api_address + '/withdrawals/' + id, {
            params: {
                token: sessionStorage.getItem('user_token'),
                user_id: sessionStorage.getItem('user_ID'),
            },
            }).then(response => {
                alert('Операция успешно отменена')
            }).catch(function (error) {
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });
        },
        cancelDeposit(id) {
            axios.delete(this.$api_address + '/deposits/' + id, {
            params: {
                token: sessionStorage.getItem('user_token'),
                user_id: sessionStorage.getItem('user_ID'),
            },
            }).then(response => {
                alert('Операция успешно отменена')
            }).catch(function (error) {
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });
        }
    }
}
</script>
<style scoped>
    .btn-outline-danger {
        color: #fff;
        border-color: #FF6D6D;
        font-size: 16px;
        width: fit-content;
    }

    .btn-outline-danger:hover {
        background-color: #FF6D6D;
    }

    @media(max-width: 768px) {
        .btn-outline-danger {
            font-size: 12px;
        }
    }
</style>
