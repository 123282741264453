<template>
  <section class="section pt-4 pt-sm-5">
    <div class="container">
      <ActivesComponent
        @buy="buy"
        @sale="sale"
        @concl="concl"
        @deposit="deposit"
        @cash="cash"
        @convert="convert"
        @verify="verify"
        @del_wallet="delete_wallet"
        @check="newCheck"
        @new_buy="newBuy"
        @new_deposit="newDeposit"
        :courses="courses"
        :user="user"
        :cards="cards"
        :wallets="wallets"
        :cards_count="cards_count"
        :wallets_count="wallets_count"
        :user_load="user_load"
        :balances="balances"
        :cryptos="cryptos"
        :checks="checks"
        :page_one_count="page_one_count"
        :page_lot_count="page_lot_count"
        :page_one="page_one"
        :page_lot="page_lot"
        :for_buy="for_buy"
        :for_deposit="for_deposit"
        :bank_list="bank_list"
        :currencies="currencies"
      />
      <div class="row py-5 flex-column align-items-end"></div>
      <HistoryComponent
        :user="user"
        :cards="cards"
        :wallets="wallets"
        :cards_count="cards_count"
        :wallets_count="wallets_count"
        :user_load="user_load"
        :balances="balances"
        :cryptos="cryptos"
        :checks="checks"
        :page_one_count="page_one_count"
        :page_lot_count="page_lot_count"
        :page_one="page_one"
        :page_lot="page_lot"
        :for_buy="for_buy"
        :for_deposit="for_deposit"
        :crypto="cryptos"
        :elements="historyWithoutCash"
        :page_count="pageCount"
        :history_load="onLoadHistory"
        :currencies="currencies"
      />
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import ActivesComponent from "@/components/Actives/ActivesComponent.vue";
import HistoryComponent from "@/components/HistoryComponent.vue";

export default {
  props: [
    "courses",
    "user",
    "cards",
    "wallets",
    "cards_count",
    "wallets_count",
    "user_load",
    "cryptos",
    "balances",
    "checks",
    "page_one",
    "page_lot",
    "for_buy",
    "for_deposit",
    "history",
    "pageCount",
    "onLoadHistory",
    "page_one_count",
    "page_lot_count",
    "bank_list",
    "currencies"
  ],
  name: "AccountView",
  components: {
    ActivesComponent,
    HistoryComponent,
  },
  methods: {
    changeCheckTypeShow() {
      if (this.checkTypeShow == "Единоразовый") {
        this.checkTypeShow = "Многоразовый";
      } else {
        this.checkTypeShow = "Единоразовый";
      }
      this.$forceUpdate();
    },
    convert(data) {
      this.$emit("convert");
    },
    newCard() {
      this.newCardFlag = true;
    },
    closeCard() {
      this.newCardFlag = false;
    },
    closeWallet() {
      this.newWalletFlag = false;
    },
    newWallet() {
      this.newWalletFlag = true;
    },
    clickCallback(page) {
      this.page = page;
    },
    buy(data) {
      console.log("buy");
      this.$emit("buy", data);
    },
    newBuy() {
      this.$emit("new_buy");
    },
    newDeposit() {
      this.$emit("new_deposit");
    },
    sale(data) {
      console.log(data);
      this.$emit("sale", data);
    },
    concl(data) {
      console.log(data);
      this.$emit("concl", data);
    },
    deposit() {
      console.log('33333333333333');
      this.$emit("deposit");
    },
    cash() {
      this.$emit("cash");
    },
    newCheck(data) {
      this.check = data;
      this.$emit("check");
    },
    verify() {
      this.$emit("verify");
    },
    showModal() {
      if (this.$metrika !== undefined) {
        this.$metrika.reachGoal("pop_up_verification");
      }
      this.isModalVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.style.overflow = "auto";
    },
    showCheck(id) {
      if (this.checks["one"][id] !== undefined) {
        this.check = this.checks["one"][id];
      }
      if (this.checks["lot"][id] !== undefined) {
        this.check = this.checks["lot"][id];
      }
      if (this.$metrika !== undefined) {
        this.$metrika.reachGoal("invoice_pop_up");
      }
      this.isNewCheckVisible = true;
      var modalElement = document.getElementById("newCheckModal");
      if (modalElement) {
        var myModal = new bootstrap.Modal(modalElement);
        myModal.show();
      }
    },
    showNewCheck() {
      this.isNewCheckVisible = true;
      this.check = "";
    },
    closeNewCheck() {
      this.isNewCheckVisible = false;
      this.check = "";
    },
    delete_wallet(data) {
      this.$emit("del_wallet", data);
    },
  },
  computed: {
    historyWithoutCash() {
            let historyWithoutCash = [];
            this.history.forEach((element) => {
                if (element.operation.operation_title !== 'Наличные') {
                    historyWithoutCash.push(element);
                }
            });
            return historyWithoutCash;
        },
  }
};
</script>
