<template>
    <div class="custom-modal__select-card mb-4 grid-cards">
        <div class='cardElement' :key="index" v-for="(data, index) in wallets" v-show="crypto.id === networks.find(network => network.id === data.network_id).crypto_id">
            <input class='bankListElement' type='radio' :id='(title ? title : null) + "wallet" + data.id' v-model='bank' :value='data.id'>
            <label v-bind:class="{'cardLabel': ((index + 1) % 4 != 0)}" style=" font-size: 10px;" :for='title + "wallet" + data.id' @click="putNumber(data.id,data.wallet_hash,data.network_id)">
                <div class="custom-modal__select-card-wrapper ">
                    <div class="custom-modal__select-card-container">
                    <img class="custom-modal__select-card-ico" :src="'/img/ico/ico-'+ (crypto ? crypto.title.toLowerCase() : 'bitcoin')+'--color.png'" width="16px" height="16px" />
                        <div class="custom-modal__select-card-right">
                            <span>{{networks.find(network => network.id === data.network_id).title}}</span>
                            <span>{{data.description}}</span>
                        </div>
                    </div>
                </div>
            </label>
        </div>
        <div class="custom-modal__select-card-wrapper">
            <div @click="newWallet" class="custom-modal__select-card-container align-items-center d-flex justify-content-center">
                <span id="addWallet-btn">+ Добавить кошелек</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['wallets','title', 'crypto'],
    created() {
        axios.get(this.$api_address +'/networks').then(response =>
        {
            this.networks = response.data.data;
        });
    },
    data() {
        return {
            bank: 0,
            active: 0,
            networks: [],
        }
    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, '$1 '),
    },
    methods: {
        wallet_delete(data){
            this.$emit('wallet_delete', data)
        },
        newWallet(){
            this.$emit('new_wallet');
        },
        putNumber(id, data, network_id) {
            this.$emit('put', {"number":data,"bank_id":0, "id":id, "commission":this.networks.find(n => n.id == network_id).commission});
        }
    }
}
</script>

<style scoped>
#addWallet-btn {
    font-size:12px;
    color: #3EA075;
    text-align: center;
}

</style>

