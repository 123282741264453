<template>
    <div class='lk__cart-item'>
        <div class="d-flex align-items-center gap-2">
            <img class='banks-logo' :src="'/img/banks/' + imgTitle + '.png'" />
            <div class="text-block d-flex flex-column" v-if="this.data.description">
                <span :style="data.active == 0 ? 'color: gray;' : ''" class='me-3 u-font-size-16'>{{ formattedDescription }}</span>
                <span :style="data.active == 0 ? 'color: gray;' : ''" class='me-3 cursor-pointer fs-7' @click="copyToClipboard">
                    {{ isCopied ? 'Скопировано!' : formattedCardNumber }}
                </span>
            </div>
            <span :style="data.active == 0 ? 'color: gray;' : ''" class='me-3 u-font-size-16 cursor-pointer' @click="copyToClipboard" v-if="!this.data.description">
                {{ isCopied ? 'Скопировано!' : formattedCardNumber }}
            </span>
        </div>
        <button class='card_delete' @click='card_delete(data.id)'><img width="14" height="14" src='/img/ico/ico-danger.svg' /></button>
    </div>
</template>

<script>
export default {
    props: ['data'],
    data() {
        return {
            isCopied: false
        }
    },
    computed: {
        imgTitle() {
            return this.data.sbp_bank ? this.data.sbp_bank.title : this.data.name;
        },
        formattedCardNumber() {
            const cardNumber = `${this.data.card_number}`;
            if (cardNumber.length > 20) {
                return cardNumber.slice(0, 7) + ' ... ' + cardNumber.slice(-7);
            } else {
                return this.formatCardNumber(cardNumber);
            }
        },
        formattedDescription() {
            const cardDescription = `${this.data.description}`;
            if (cardDescription.length > 20) {
                return cardDescription.slice(0, 20) + ' ... ';
            } else {
                return cardDescription;
            }
        }
    },
    methods: {
        formatCardNumber(val) {
            return `${val}`.replace(/(\d{4})(?=\d)/g, '$1 ');
        },
        card_delete(id) {
            eventBus.$emit('card_delete', id);
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.data.card_number).then(() => {
                this.isCopied = true;
                setTimeout(() => {
                    this.isCopied = false;
                }, 1000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    }
}
</script>

<style scoped>
.fs-7 {
    font-size: 13px;
}
@media (max-width:500px) {
    .fs-7 {
        font-size: 10px;
    }
}
</style>