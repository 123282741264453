<template>
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' id='verificationModal' style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' aria-hidden='true' tabindex='-1'>
            <div class='modal-dialog modal-dialog-centered'>
                <div v-if="isChangeMail == false && step < 5" class='modal-content w-500'>
                    <div class='modal-header' >
                        <h4 class='modal-title'>Верификация</h4>
                        <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                    </div>
                    <div v-if="step < 3" class="modal-body register_block">
                        <p style="font-size: 13px; line-height: 19px; font-weight: 400; font-family: Montserrat;">
                            Все данные, отправленные в данном окне, будут зашифрованы и надежно хранится на наших распределенных серверах, данные никогда не будут переданы третьим лицам
                        </p>
                        <input id="ver_fio" @change="checkALlFields" class='o-input mb-2' style="text-align: center" v-model='fio' type='text' placeholder='ФИО' />
                        <div style="display: flex;  justify-content: space-between; align-items: center; padding: 10px 0 20px; margin-top: 15px; width: 100%;">
                                <span v-if="mail" class='custom-file__text verification_file'>{{mail}}</span>
                                <span v-else class='custom-file__text verification_file'>{{user ? user.email : ''}}</span>
                            <span @click='switchBlock' class='custom-file__text link'>Изменить</span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <vue-tel-input v-if="isCode == false && step < 2" @change="checkALlFields" :autoFormat="false" :styleClasses="'text-dark  w-60'" v-model="phone" :dropdownOptions="dropdownOptions" :inputOptions="inputOptions">
                                <template slot="arrow-icon">
                                    <span></span>
                                </template>
                            </vue-tel-input>
                            <span v-if="isCode == true || step == 2" class='custom-file__text verification_file_2'>{{phone}}</span>
                            <span v-if="isCode == true || step == 2" @click='changePhone' class='custom-file__text link'>Изменить</span>
                            <span v-if="step != 2 && isCode == false" @click='checkPhone' class='custom-file__text link mt-3'>Подтвердить</span>
                        </div>
                        <div class="error-block-text" v-if="step == 2 && isCode !== true"><p>На этот номер придет звонок, введите последние 4 цифры входящего номера</p></div>

                        <div v-if="step == 2 && isCode ==  false" class="d-flex justify-content-between align-items-center w-100">
                            <input id="ver_codeForPhone" style='width: 60%; text-align: center' v-model='codeForPhone'  class='o-input mb-2' type='text' placeholder='код из номера' />
                            <div class="d-flex flex-column">
                                <span @click='checkCode' class='custom-file__text link mt-3'>Подтвердить</span>
                                <span v-if="countDown == 30" @click='countDownTimer' class="custom-file__text link" style="font-size:12px;">Новый звонок</span>
                                <span v-else class="custom-file__text" style="font-size: 12px; color: #69E278;">Будет доступно через:<br> {{countDown}} секунд</span>
                            </div>
                        </div>
                        <div class="error-block-text" v-if="errorText.phone"><p>{{errorText.phone}}</p></div>
                        <button v-if="isCode == true && fio != ''" style="margin-top: 20px;" class='button button--full-width' @click='next'>Далее</button>
                        <button v-else disabled="disabled" style="margin-top: 20px;" class='button button--full-width'>Далее</button>

                    </div>
                    <div v-else-if="step === 3" class="modal-body">
                        <span>Прикрепите фотографии подтверждающие вашу личность</span>

                        <input @change="checkALlFields" id='js-file-2' style='cursor: pointer; display: none;' 
                        @input='addPass1' slot=''  class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                        <label class='custom-file__label w-100 light_link_300' for='js-file-2'>
                            <i class='custom-file__icon'></i>
                            <span class='custom-file__text light_link'>
                                <img src="/img/ico/ico-upload.svg" alt="фото" width="23" height="19">
                                <span v-if="pass1">{{pass1.name}}</span>
                                <span v-else>Фото паспорта (лицевая сторона)</span>
                            </span>
                        </label>

                        <input @change="checkALlFields" id='js-file-3' style='cursor: pointer; display: none;' 
                        @input='addPass2' slot='' class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                        <label class='custom-file__label w-100 light_link_300' for='js-file-3'>
                            <i class='custom-file__icon'></i>
                            <span class='custom-file__text light_link'>
                                <img src="/img/ico/ico-upload.svg" alt="фото" width="23" height="19">
                                <span v-if="pass2">{{pass2.name}}</span>
                                <span v-else>Фото паспорта(прописка)</span>
                            </span>
                        </label>

                        <input @change="checkALlFields" id='js-file-4' style='cursor: pointer; display: none;' 
                        @input='addPass3' slot='' class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                        <label class='custom-file__label w-100 mb-4 light_link_300' for='js-file-4'>
                            <i class='custom-file__icon'></i>
                            <span class='custom-file__text light_link'>
                                <img src="/img/ico/ico-upload.svg" alt="фото" width="23" height="19">
                                <span v-if="pass3">{{pass3.name}}</span>
                                <span v-else>Селфи с паспортом (лицевая сторона)</span>
                            </span>
                        </label>


                        <div style="display: flex;">
                            <span style="font-size: 16px; color: #3EA075;">*</span>
                            <span style="color: #3EA075; margin-bottom:0;font-size: 12px; line-height: 19px; font-weight: 400; font-family: Montserrat;">При загрузке документов используйте файлы форматов: png, jpeg, pdf.
                            Максимальный размер файла - 5 мб.</span>
                        </div>
                        <div style="margin-top: 20px; display: flex; flex-wrap: wrap; justify-content: space-evenly">
                            <button style='width: 45%' class='button button--full-width' @click='prev'>Назад</button>
                            <button :disabled='ALlFields == true ? false : true' style='width: 45%' class='button button--full-width' @click='send'>Отправить</button>
                        </div>
                    </div>

                    <div v-else class='modal-body pe-4'>
                        <p style="font-size: 13px; line-height: 19px; font-weight: 400; font-family: Montserrat;">Данные успешно отправлены</p>
                        <h3>Срок верификации от 30 до 60 минут
                        </h3>
                        <button style='margin-top: 20px;' class='button button--full-width' @click='close'>Окей</button>
                    </div>
                </div>

                <div v-if="isChangeMail == true" class='modal-content w-500'>
                    <div class='modal-header'>
                        <h5 class='modal-title'>Изменить Email</h5>
                        <button class='btn-close btn-close-white' type='button' data-bs-dismiss='modal' @click='back' aria-label='Close'></button>
                    </div>
                    <div class='modal-body register_block'>
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <input id="ver_checkMail" class='o-input mb-2' type='text' style="width: 60%;" v-model='newMail' placeholder='Email' />
                            <span @click='checkMail' class='custom-file__text link'>Подтвердить</span>
                        </div>
                        <div class="error-block-text" v-if="errorText.mail"><p>{{errorText.mail}}</p></div>
                        <input id="ver_codeForMail" v-if="stepMail == 2" class='o-input mb-2' type='text' v-model='codeForMail' placeholder='Код из Email' />
                        <button style="margin-top: 20px;" class='button button--full-width' :disabled='stepMail == 2 ? false : true' @click='changeMail()'>Изменить</button>
                    </div>
                </div>

                </div>
            </div>
    </transition>
</template>
<script>

import { VueTelInput } from 'vue-tel-input';
export default {
    props:['user'],
    mounted() {
        const input = document.querySelector("#unique_phone");
        //mask("#unique_phone");
    },
    components: {
        VueTelInput,
    },
    data () {
        return {
            step: 1,
            stepMail: 1,
            selfi: '',
            pass1: '',
            pass2: '',
            pass3: '',
            reader: '',
            fio: '',
            mail: '',
            phone: '',
            codeForPhone: '',
            codePhone: '123',
            codeForMail: '',
            codeMail: '123',
            newMail:'',
            maxSize: 5242880,
            ALlFields: false,
            isCode: false,
            isCodeMail: false,
            isChangeMail: false,
            errorText: {
                phone: '',
                mail: '',
            },
            new_call: false,
            countDown: 30,
            dropdownOptions: {
                disabled: false,
                showDialCodeInSelection: false,
                showDialCodeInList: true,
                showSearchBox: false,
                showFlags: true,
            },
            inputOptions: {
                placeholder: 'Номер телефона',
                showDialCode: true,
                styleClasses: 'mt-0 p-all-5px',
            },
            session_id: null,
        }
    },
    watch: {
        'user.status': function(newValue, oldValue) {
            if (newValue === 4) {
                this.step = 1;
            }
        }
    },
    methods: {
        countDownTimer() {
            if(this.new_call == false) {
                this.new_call = true;
                this.checkPhone();
            }
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
            if(this.countDown == 0) {
                this.countDown = 30;
                this.new_call = false;
            }
        },
        next(){
          if(this.step < 3) {
              if(this.$metrika !== undefined) {
                  this.$metrika.reachGoal('verification_2nd_step');
              }
              this.step = 3;
          }  else {
              this.step++;
          }
        },
        prev(){
            if(this.phone != '' && this.isCode == false) {
                this.step = 2;
            } else {
                this.step = 1;
            }
        },
        addSelfi(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                    this.selfi = event.target.files[0];
            } else {
                alert('Неверный формат или размер файла превышает 5Мб');
                this.selfi = '';
            }
        },
        addPass1(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                this.pass1 = event.target.files[0];
            } else {
                alert('Неверный формат или размер файла превышает 5Мб');
                this.pass1 = '';
            }
        },
        addPass2(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                this.pass2 = event.target.files[0];
            } else {
                alert('Неверный формат или размер файла превышает 5Мб');
                this.pass2 = '';
            }
        },
        addPass3(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                this.pass3 = event.target.files[0];
            } else {
                alert('Неверный формат или размер файла превышает 5Мб');
                this.pass3 = '';
            }
        },
        send() {
            // if(this.step == 3) {
                if(this.mail == '') {
                    this.mail = this.user.email;
                }
                let formData = new FormData();
                formData.append("mail", this.mail);
                formData.append("phone_number", this.phone);
                formData.append("full_name", this.fio);
                formData.append("main_passport_image", this.pass1);
                formData.append("registration_passport_image", this.pass2);
                formData.append("selfie_image", this.pass3);
                formData.append("token", sessionStorage.getItem('user_token'));
                formData.append("user_id", sessionStorage.getItem('user_ID'));
                formData.append("_method", "PUT");
                axios.post(this.$api_address + '/users/' + this.$user_ID.value + '/verify', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.step = 4;
                    console.log('step', this.step);
                    this.$emit('verify');
                    if(this.$metrika !== undefined) {
                        this.$metrika.reachGoal('verif_application');
                    }
                });
            // }
        },

        //Проверка кода
        checkCode() {
            let data = {
                session_id: this.session_id,
                code: this.codeForPhone,
            }
            console.log('DataFromVer', data);
            axios.post(this.$api_address + '/users/check_code', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }).then(response => {
                if(typeof(response.data.data.response) != "undefined" && response.data.data.response !== null) {
                    this.isCode = true;
                    this.errorText.phone = '';
                    phone-block-text.hide();
                    this.step = 2;
                    this.checkALlFields();
                } else {
                    this.errorText.phone = response.data.error;
                }
            })
        },

        /*Регистрируем телефон*/
        checkPhone() {
            let data = {
                phone: this.phone,
                token: sessionStorage.getItem('user_token'),
                user_id: this.$user_ID.value
            }
            axios.post(this.$api_address + '/users/register_phone', data, {
            }).then(response => {

                this.step = 2;
                this.session_id = response.data.data.session_id
            }).catch(function (error) {
                this.errorText.phone = 'Что-то пошло не так, попробуйте снова';
            });
        },

        checkMail() {
            let data = { email: this.newMail }
            axios.post(this.$api_address + '/users/register_email', data, {
                validateStatus: function (status) {
                    return status < 500;
                }
            }).then(response => {
                if(response.data.data) {
                    this.stepMail = 2;
                } else {
                    console.log('Ошибочка мыла');
                }
            }).catch(function (error){
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });
        },

        changePhone() {
            this.isCode = false;
            this.codeForPhone = '';
            this.checkALlFields();
            this.step = 1;
        },
        checkALlFields() {
            if(this.pass1 != '' && this.pass2 != '' && this.pass3 != '' && this.fio != '' && this.phone != '' && this.isCode == true) {
                this.ALlFields = true;
            } else {
                this.ALlFields = false;
            }
        },
        close(event) {
            this.$emit('close');
        },
        back() {
            this.isChangeMail = false;
        },
        switchBlock() {
            if(this.isChangeMail == false) {
                this.isChangeMail = true;
            }
        },

        /*Меняем мыло*/
        changeMail() {
            console.log('Меняем mail');
            let data = {
                token: sessionStorage.getItem('user_token'),
                user_id: sessionStorage.getItem('user_ID'),
                email: this.newMail,
            }
            axios.put(this.$api_address + '/users/' + this.$user_ID.value + '/change_email', data, {
            }).then(response => {
                if(response.data.data) {
                    this.isCodeMail = true;
                    this.errorText.mail = '';
                    this.mail = this.newMail;
                    this.newMail  = '';
                    this.codeForMail = '';
                    this.stepMail = 1;
                    this.back();
                } else {
                    this.errorText.mail = response.data.error;
                }
            }).catch(function (error) {
                this.errorText.mail = 'Что-то пошло не так, попробуйте снова';
            });

        }
    }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style>
    .vti__dropdown-item {
        color:#000;
    }
</style>
